.p-sidebar {
  background-color: $color-bg--sidebar;
  flex: none;
  //overflow: hidden;
  margin-bottom: 0;
  padding-bottom: 0;
  position: relative;
  top: 0;
  transition: $transition--default;
  z-index: 1;
  width: 260px;

  .page.sidebar-is-visible & {

  }

  &:before {
    content: '';
    position: absolute;
    width: 260px;
    height: calc(100vh - 80px);
    top: 0;
    background-color: $color-bg--sidebar;
  }
}
