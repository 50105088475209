.main-logo {
  display: block;
  height: 42px;
  width: 110px;

  & > img {
    display: block;
    height: auto;
    width: 100%;
  }
}
