.p-wrapper {
  margin: 0 auto;
  overflow: visible;
  position: relative;
  width: 100%;
  min-width: 1180px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &--registration {
    overflow: unset;
    min-width: 1200px;
  }
}
