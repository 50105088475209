$font--strategy-lg: 24px;
$font--strategy-xl: 26px;

.strategy-list {
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
}

.strategy-list__item {
  background-color: #f2f5f8;
  border-radius: 6px;
  overflow: hidden;
  padding: 15px 0;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.strategy-list__preview {
  padding: 0 20px;
  position: relative;
}

.strategy-list__preview-graphic {
  bottom: -15px;
  height: 56px;
  right: 0;
  position: absolute;
  width: 170px;

  & > img {
    display: block;
    height: auto;
    width: 100%;
  }
}

/* Columns */
.strategy-list__col {
  float: left;
  display: block;
  padding-top: 5px;
}

.strategy-list__col-label {
  color: #939da8;
  margin: 0;
}

/* Column: Item name */
.strategy-list__col--item-name {
  text-align: left;
  width: 240px;
}

.strategy-list__item-icon {
  border-radius: 50%;
  border: 1px solid #939da8;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  margin-top: -5px;
  position: relative;
  float: left;

  & > img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
}

.strategy-list__item-link {
  color: $color-link--base;
  display: inline-block;
  font-size: $font--strategy-xl;
  font-weight: $font--bold;
  height: 28px;
  line-height: 1;
  position: relative;
  text-decoration: none;
  vertical-align: middle;
  transition: $transition--default;

  & > span {
    border-bottom: 1px dotted $color-link--base;
    display: inline-block;
    vertical-align: middle;
  }

  &:hover {
    color: $color-link--accent;

    & > span {
      border-bottom-color: $color-link--accent;
    }
  }

  &.detail-opened {
    color: $color-link--base;

    & > span {
      border-bottom-color: transparent;
    }
  }
}

/* Column: Expected return */
.strategy-list__col--expected-return {
  width: 170px;

  & > .return-percents {
    font-size: $font--strategy-xl;
    font-weight: $font--bold;
    height: 28px;
    line-height: 1;
    margin: 0;

    & > span {
      font-size: $font--xm;
      font-weight: $font--normal;
    }
  }
}

/* Column: Start sum */
.strategy-list__col--start-sum {
  width: 160px;

  & > .start-sum {
    font-size: $font--strategy-xl;
    font-weight: $font--bold;
    height: 28px;
    line-height: 1;
    margin: 0;

    & > .currency-icon {
      font-size: $font--lg;
      font-weight: $font--normal;

      &::before {
        line-height: 1;
        margin: 0;
        vertical-align: baseline;
        width: auto;
      }
    }
  }
}

/* Column: Leverage */
.strategy-list__col--leverage {
  width: 150px;

  & > .leverage-switcher {
    background-color: $color-link--base;
    border-radius: 15px;
    height: 30px;
    font-size: 0;
    margin-bottom: 3px;
    margin-top: -5px;
    padding: 2px 2px;
    width: 114px;

    & > .btn {
      background-color: transparent;
      border-radius: 13px;
      color: #fff;
      font-size: $font--sm;
      font-weight: $font--bold;
      height: 26px;
      text-align: center;
      width: 34px;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }

    & > .btn.is-active {
      background-color: #fff;
      color: $color-link--base;
    }
  }
}

/* Column: Subscribe */
.strategy-list__col--subscribe {
  width: 120px;

  & .btn {
    margin-top: -5px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

/*** Detail block ***/
.strategy-list__detail {
  background-color: #fff;
  border: 1px solid #d8dfe6;
  border-radius: 0 0 6px 6px;
  display: none;
  margin-bottom: -15px;
  margin-top: 25px;
  padding-top: 40px;
  position: relative;
  z-index: 0;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    transform: rotate(-45deg);
  }

  &::before {
    border-right: 1px solid #d8dfe6;
    border-top: 1px solid #d8dfe6;
    height: 19px;
    left: 99px;
    top: -10px;
    width: 19px;
  }

  &:after {
    background-color: #fff;
    left: 100px;
    width: 17px;
    height: 17px;
    top: -9px;
  }

  & > .detail-content {
    padding: 0 20px;

    &:after {
      clear: both;
      content: "";
      display: table;
    }

    & > .detail-content__left-part {
      float: left;
      margin-right: 18px;
      width: 560px;
    }

    & > .detail-content__right-part {
      float: left;
      width: 320px;
    }
  }

  & .detail-content__label {
    color: #a6acb0;
    font-size: $font--md;
    margin: 0 0 10px;
  }
}

/* Strategy description */
.strategy-description {
  border-bottom: 2px solid #d8dfe6;
  padding-bottom: 20px;

  & > p {
    font-size: $font--xm;
    margin: 0 0 10px;
  }

  & > .btn {
    padding: 9px 17px 8px;
  }
}

/* Strategy risk level */
.strategy-risk-level {
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 32px 28px;
  border-bottom: 2px solid #d8dfe6;
  font-weight: $font--bold;
  padding: 20px 0 15px 40px;

  &--min {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2232%22%20height%3D%2228%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22%2376B345%22%20d%3D%22M29.402%2028H2.594C.602%2028-.643%2025.872.354%2024.17L13.756%201.277C14.255.427%2015.127%200%2015.997%200c.872%200%201.743.426%202.24%201.277l13.405%2022.895c.996%201.7-.25%203.827-2.24%203.827zm.482-2.826L16.48%202.278c-.133-.227-.336-.275-.482-.275-.147%200-.35.048-.483.275L2.112%2025.174c-.133.226-.074.423%200%20.548.073.126.216.275.482.275h26.808c.265%200%20.408-.15.482-.275.073-.125.133-.322%200-.548zM16%2023c-.552%200-1-.448-1-1v-9c0-.552.448-1%201-1s1%20.448%201%201v9c0%20.552-.448%201-1%201z%22/%3E%3C/svg%3E');
  }

  &--mid {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2232%22%20height%3D%2228%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22%23FDD655%22%20d%3D%22M29.402%2028H2.594C.602%2028-.643%2025.872.354%2024.17L13.756%201.277C14.255.427%2015.127%200%2015.997%200c.872%200%201.743.426%202.24%201.277l13.405%2022.895c.996%201.7-.25%203.827-2.24%203.827zm.482-2.826L16.48%202.278c-.133-.227-.336-.275-.482-.275-.147%200-.35.048-.483.275L2.112%2025.174c-.133.226-.074.423%200%20.548.073.126.216.275.482.275h26.808c.265%200%20.408-.15.482-.275.073-.125.133-.322%200-.548zM18%2023c-.552%200-1-.448-1-1v-9c0-.552.448-1%201-1s1%20.448%201%201v9c0%20.552-.448%201-1%201zm-4%200c-.552%200-1-.448-1-1v-9c0-.552.448-1%201-1s1%20.448%201%201v9c0%20.552-.448%201-1%201z%22/%3E%3C/svg%3E');
  }

  &--max {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2232%22%20height%3D%2228%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22%23EB533F%22%20d%3D%22M29.402%2028H2.594C.602%2028-.642%2025.872.354%2024.17L13.756%201.277C14.255.427%2015.127%200%2015.997%200c.872%200%201.743.426%202.24%201.277l13.405%2022.895c.996%201.7-.25%203.827-2.24%203.827zm.482-2.826L16.48%202.278c-.133-.227-.336-.275-.482-.275-.147%200-.35.048-.483.275L2.11%2025.174c-.13.226-.072.423%200%20.548.075.126.218.275.484.275h26.808c.265%200%20.41-.15.482-.275.073-.125.133-.322%200-.548zM20%2023c-.552%200-1-.448-1-1v-9c0-.552.448-1%201-1s1%20.448%201%201v9c0%20.552-.448%201-1%201zm-4%200c-.552%200-1-.448-1-1v-9c0-.552.448-1%201-1s1%20.448%201%201v9c0%20.552-.448%201-1%201zm-4%200c-.552%200-1-.448-1-1v-9c0-.552.448-1%201-1s1%20.448%201%201v9c0%20.552-.448%201-1%201z%22/%3E%3C/svg%3E');
  }
}

/* Strategy yield */
.strategy-yield {
  border-bottom: 2px solid #d8dfe6;
  padding: 10px 0;
}

.strategy-yield__periods {
  overflow: hidden;
}

.strategy-yield__period {
  float: left;
  width: 33.3333333%
}

.strategy-yield__period-number {
  font-size: $font--strategy-lg;
  font-weight: $font--bold;
  line-height: 1;
  margin: 0;

  & > span {
    font-size: $font--xm;
    font-weight: $font--normal;
  }
}

.strategy-yield__period-name {
  color: #939da8;
  font-size: $font--md;
  margin: 0;
}

/* Strategy max. dropdown */
.strategy-max-dropdown {
  border-bottom: 2px solid #d8dfe6;
  padding: 10px 0 15px;

  & > .max-dropdown {
    font-size: $font--strategy-lg;
    font-weight: $font--bold;
    line-height: 1;
    margin: 0;

    & > span {
      font-size: $font--xm;
      font-weight: $font--normal;
    }
  }
}

/* Strategy investing */
.strategy-investing {
  padding-top: 10px;

  & > .detail-content__label {
    margin-bottom: 5px;
  }

  & .currency-icon::before {
    line-height: 1;
    margin: 0;
    vertical-align: baseline;
    width: auto;
  }
}

.strategy-investing__group {
  background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%22283%22%20height%3D%2219.97%22%20viewBox%3D%220%200%20283%2019.97%22%3E%3Cpath%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke%3D%22%23d8dfe6%22%20d%3D%22M1.5.5s.713%2010%2010%2010h121c4.482%200%209%207%209%207s3.68-7%209-7h121c9.658%200%2010-10%2010-10%22%20class%3D%22cls-1%22/%3E%3C/svg%3E') 0 100% no-repeat;
  background-size: 280px 20px;
  color: #fff;
  font-size: $font--xm;
  padding-bottom: 20px;
  overflow: hidden;
  width: 280px;

  & .currency-icon::before {
    font-size: $font--md;
  }
}

.strategy-investing__plus-item {
  background-color: #ced7e0;
  border-radius: 11px 0 0 11px;
  float: left;
  height: 22px;
  line-height: 22px;
  margin-right: 5px;
  padding-left: 12px;
  width: 186px;
}

.strategy-investing__minus-item {
  background-color: #76b345;
  border-radius: 0 11px 11px 0;
  float: left;
  height: 22px;
  line-height: 22px;
  text-align: right;
  padding-right: 12px;
  width: 89px;
}

.strategy-investing__total {
  margin: 0;
  text-align: center;
  width: 280px;

  & > .total-value {
    & > .currency-icon::before {
      font-size: $font--sm;
    }
  }
}

/* Investment portfolio */
.strategy-portfolio {
  margin-bottom: 20px;

  & > h4 {
    font-size: $font--xl;
    margin: 0 0 10px;
  }
}

.strategy-portfolio__table {
  border-collapse: separate;
  border-spacing: 0 5px;
  line-height: 1;
  table-layout: fixed;

  & > thead {
    & th {
      color: #7cabd5;
      font-size: $font--sm;
      height: 35px;
      border-bottom: 1px solid #d8dfe6;
      text-align: left;
      vertical-align: top;

      & .currency-icon::before {
        font-size: $font--xs;
        line-height: 1;
        margin: 0;
        vertical-align: baseline;
        width: auto;
      }
    }
  }

  & > tbody {
    & td {
      background-color: #fff;
      font-size: $font--md;
      height: 60px;
      padding: 5px 0;
      position: relative;
      vertical-align: middle;

      &:after {
        background-color: #fff;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: $transition--default;
        width: 100%;
        z-index: -1;
      }

      &.is-increased {
        color: $color-status--success;
      }

      &.is-decreased {
        color: $color-status--error;
      }

      & > div {
        display: block;
        height: 100%;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 100%;

        & > p {
          display: block;
          margin: 0;
          padding: 0;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  & > thead th:first-child,
  & > tbody td:first-child {
    padding-left: 10px;
  }

  & > tbody tr:nth-child(even) td {
    background-color: #f4f7fa;

    &:after {
      background-color: #f4f7fa;
    }
  }

  & > thead th:nth-child(1) {
    width: 107px;
  }

  & thead th:nth-child(2) {
    width: 65px;
  }

  & thead th:nth-child(3) {
    width: 100px;
  }

  & thead th:nth-child(4) {
    width: 100px;
  }

  & thead th:nth-child(5) {
    width: 90px;
  }

  & thead th:nth-child(6) {
    width: 95px;
  }

  & > tbody tr.is-out-of-market > td {
    color: rgba(33, 35, 37, 0.4);
  }

  & > tbody tr:hover {
    td:after {
      box-shadow: 0 0 10px 0 rgba(18, 47, 91, 0.2);
    }
  }
}

/* Strategy result chart */
.strategy-result-chart {
  margin-bottom: 25px;
  text-align: center;

  & > div {
    margin-bottom: 40px;
  }

  & > .hide-btn {
    border: 1px solid transparent;
    border-radius: 17px;
    color: $color-link--base;
    cursor: pointer;
    display: inline-block;
    font-size: $font--md;
    font-weight: $font--bold;
    vertical-align: top;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
    transition: $transition--default;

    & > span {
      border-bottom: 1px dotted $color-link--base;
      display: inline-block;
      vertical-align: middle;
    }

    &:hover {
      color: $color-link--accent;

      & > span {
        border-bottom-color: $color-link--accent;
      }
    }
  }
}
