.file-upload {
  position: relative;
  width: 100%;

  & input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    text-align: right;
    opacity: 0;
    background: 0 0;
    cursor: inherit;
    display: block;
  }

  &__caption {
    background-color: #fff;
    border: 1px solid #d8dfe6;
    border-radius: 15px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    padding: 0 25px 0 15px;
    position: relative;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;

    & > span {
      color: #aaafb4;
      cursor: default;
      font-size: inherit;
    }

    .file-upload:focus & {
      box-shadow: 0 2px 6px 0 rgba(18, 47, 91, 0.06);
    }

    .file-upload.has-error & {
      border-color: $color-status--error;
    }

    .file-upload.is-valid & {
      border-color: $color-status--success;
    }

    .file-upload[disabled] & {
      background-color: #f5f6f7;
      cursor: not-allowed;
      opacity: 0.8;
    }
  }

  &__btn-group {
    display: block;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);

    & > .btn {
      overflow: hidden;

      .file-upload[disabled] & {
        background-color: transparent;
        border-color: $color-icon--base;
        fill: $color-icon--base;
        opacity: 0.5;
        pointer-events: none;
        z-index: -1;
      }
    }
  }
}
