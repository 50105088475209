.request-status {
  background-repeat: no-repeat;
  display: inline-block;
  font-size: $font--md;
  padding-left: 31px;
  transition: $transition--default;
  vertical-align: middle;

  &.is-approved {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2221%22%20height%3D%2219%22%20fill-rule%3D%22evennodd%22%20fill%3D%22%2398a3af%22%3E%3Cpath%20d%3D%22M18%2010.373V19H0V4h10.233c.657-2.303%202.753-4%205.267-4C18.538%200%2021%202.462%2021%205.5c0%202.132-1.226%203.96-3%204.873zM10%205.5c0-.202.038-.394.06-.59-.035.04-.058.07-.06.09H1v13h16v-7.233c-.48.136-.976.233-1.5.233-1.7%200-3.2-.788-4.21-2H5V8h5.627C10.24%207.247%2010%206.405%2010%205.5zM15.5%201C13.015%201%2011%203.015%2011%205.5s2.015%204.5%204.5%204.5S20%207.985%2020%205.5%2017.985%201%2015.5%201zM18%205V4h1v1h-1zm-1%201V5h1v1h-1zm-1%201V6h1v1h-1zm-1%201V7h1v1h-1zm-1-2h1v1h-1V6zm-1-1h1v1h-1V5zM3%208V7h1v1H3zM2%209V8h1v1H2zm-1%201V9h1v1H1zm3-4h1v1H4V6zm2-1v1H5V5h1zM1%207V6h1v1H1zm1-2h1v1H2V5zm8%206H3v-1h7v1zm-1%202H3v-1h6v1zm2%204H3v-3h8v3zm-6-2H4v1h1v-1zm5%200H6v1h4v-1zm6%200h-4v-1h4v1z%22/%3E%3C/svg%3E');

    .data-table tr:hover & {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2221%22%20height%3D%2219%22%20fill-rule%3D%22evennodd%22%20fill%3D%22%2376b345%22%3E%3Cpath%20d%3D%22M18%2010.373V19H0V4h10.233c.657-2.303%202.753-4%205.267-4C18.538%200%2021%202.462%2021%205.5c0%202.132-1.226%203.96-3%204.873zM10%205.5c0-.202.038-.394.06-.59-.035.04-.058.07-.06.09H1v13h16v-7.233c-.48.136-.976.233-1.5.233-1.7%200-3.2-.788-4.21-2H5V8h5.627C10.24%207.247%2010%206.405%2010%205.5zM15.5%201C13.015%201%2011%203.015%2011%205.5s2.015%204.5%204.5%204.5S20%207.985%2020%205.5%2017.985%201%2015.5%201zM18%205V4h1v1h-1zm-1%201V5h1v1h-1zm-1%201V6h1v1h-1zm-1%201V7h1v1h-1zm-1-2h1v1h-1V6zm-1-1h1v1h-1V5zM3%208V7h1v1H3zM2%209V8h1v1H2zm-1%201V9h1v1H1zm3-4h1v1H4V6zm2-1v1H5V5h1zM1%207V6h1v1H1zm1-2h1v1H2V5zm8%206H3v-1h7v1zm-1%202H3v-1h6v1zm2%204H3v-3h8v3zm-6-2H4v1h1v-1zm5%200H6v1h4v-1zm6%200h-4v-1h4v1z%22/%3E%3C/svg%3E');
    }
  }

  &.is-declined {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2221%22%20height%3D%2219%22%20fill-rule%3D%22evennodd%22%20fill%3D%22%2398a3af%22%3E%3Cpath%20d%3D%22M18%2010.373V19H0V4h10.233c.657-2.303%202.753-4%205.267-4C18.538%200%2021%202.462%2021%205.5c0%202.132-1.226%203.96-3%204.873zM10%205.5c0-.202.038-.394.06-.59-.035.04-.058.07-.06.09H1v13h16v-7.233c-.48.136-.976.233-1.5.233-1.7%200-3.2-.788-4.21-2H5V8h5.627C10.24%207.247%2010%206.405%2010%205.5zM15.5%201C13.015%201%2011%203.015%2011%205.5s2.015%204.5%204.5%204.5S20%207.985%2020%205.5%2017.985%201%2015.5%201zM17%207h1v1h-1V7zm-1-1h1v1h-1V6zm-2%201V6h1v1h-1zm-1%201V7h1v1h-1zm2-3h1v1h-1V5zm-1-1h1v1h-1V4zm-1-1h1v1h-1V3zm4%201v1h-1V4h1zm1-1v1h-1V3h1zM3%208V7h1v1H3zM2%209V8h1v1H2zm-1%201V9h1v1H1zm3-4h1v1H4V6zm2-1v1H5V5h1zM1%207V6h1v1H1zm1-2h1v1H2V5zm8%206H3v-1h7v1zm-1%202H3v-1h6v1zm2%204H3v-3h8v3zm-6-2H4v1h1v-1zm5%200H6v1h4v-1zm6%200h-4v-1h4v1z%22/%3E%3C/svg%3E');

    .data-table tr:hover & {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2221%22%20height%3D%2219%22%20fill-rule%3D%22evennodd%22%20fill%3D%22%23f65516%22%3E%3Cpath%20d%3D%22M18%2010.373V19H0V4h10.233c.657-2.303%202.753-4%205.267-4C18.538%200%2021%202.462%2021%205.5c0%202.132-1.226%203.96-3%204.873zM10%205.5c0-.202.038-.394.06-.59-.035.04-.058.07-.06.09H1v13h16v-7.233c-.48.136-.976.233-1.5.233-1.7%200-3.2-.788-4.21-2H5V8h5.627C10.24%207.247%2010%206.405%2010%205.5zM15.5%201C13.015%201%2011%203.015%2011%205.5s2.015%204.5%204.5%204.5S20%207.985%2020%205.5%2017.985%201%2015.5%201zM17%207h1v1h-1V7zm-1-1h1v1h-1V6zm-2%201V6h1v1h-1zm-1%201V7h1v1h-1zm2-3h1v1h-1V5zm-1-1h1v1h-1V4zm-1-1h1v1h-1V3zm4%201v1h-1V4h1zm1-1v1h-1V3h1zM3%208V7h1v1H3zM2%209V8h1v1H2zm-1%201V9h1v1H1zm3-4h1v1H4V6zm2-1v1H5V5h1zM1%207V6h1v1H1zm1-2h1v1H2V5zm8%206H3v-1h7v1zm-1%202H3v-1h6v1zm2%204H3v-3h8v3zm-6-2H4v1h1v-1zm5%200H6v1h4v-1zm6%200h-4v-1h4v1z%22/%3E%3C/svg%3E');
    }
  }

  &.is-pending {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2221%22%20height%3D%2219%22%20fill-rule%3D%22evennodd%22%20fill%3D%22%2398a3af%22%3E%3Cpath%20d%3D%22M15.544%2010.422C15.824%209.665%2016%208.855%2016%208c0-3.866-3.134-7-7-7-.855%200-1.665.175-2.422.456L5.794.673C6.776.243%207.86%200%209%200c4.418%200%208%203.582%208%208%200%201.14-.243%202.224-.673%203.206l-.783-.784zm-4.122%204.122l.784.783C11.224%2015.757%2010.14%2016%209%2016c-4.418%200-8-3.582-8-8%200-1.14.243-2.224.673-3.206l.783.784C2.176%206.335%202%207.145%202%208c0%203.866%203.134%207%207%207%20.855%200%201.665-.175%202.422-.456zM4%203v4L0%203h4zm10%2010V9l4%204h-4z%22/%3E%3C/svg%3E');

    .data-table tr:hover & {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2221%22%20height%3D%2219%22%20fill-rule%3D%22evennodd%22%20fill%3D%22%23fdd655%22%3E%3Cpath%20d%3D%22M15.544%2010.422C15.824%209.665%2016%208.855%2016%208c0-3.866-3.134-7-7-7-.855%200-1.665.175-2.422.456L5.794.673C6.776.243%207.86%200%209%200c4.418%200%208%203.582%208%208%200%201.14-.243%202.224-.673%203.206l-.783-.784zm-4.122%204.122l.784.783C11.224%2015.757%2010.14%2016%209%2016c-4.418%200-8-3.582-8-8%200-1.14.243-2.224.673-3.206l.783.784C2.176%206.335%202%207.145%202%208c0%203.866%203.134%207%207%207%20.855%200%201.665-.175%202.422-.456zM4%203v4L0%203h4zm10%2010V9l4%204h-4z%22/%3E%3C/svg%3E');
    }
  }
}
