.user-identity {
  position: relative;
  display: table;
  vertical-align: middle;

  &__avatar {
    display: table-cell;
    height: 40px;
    vertical-align: middle;
    width: 40px;

    & > img {
      display: block;
      height: auto;
      width: 100%;
    }
  }

  &__info {
    display: table-cell;
    padding-left: 10px;

    & > span {
      display: block;
      font-size: $font--sm;
      white-space: nowrap;
    }
  }

  &__name {
    color: $color-link--base;
    display: inline-block;
    font-weight: $font--bold;
    text-decoration: none;
    vertical-align: middle;

    &:hover {
      color: $color-link--accent;
    }
  }

  &:after {
    clear: both;
    content: "";
    display: table;
  }

  .p-header__user-info > & {
    float: left;
    top: 50%;
    transform: translateY(-50%);
  }
}
