.registration {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 10px;
  width: 100%;

  & h1 {
    font-family: $font-sans-caption;
    font-size: 30px;
    text-align: center;
  }

  & h2 {
    color: #212325;
    font-family: $font-sans-caption;
    font-size: 18px;
    font-weight: $font--bold;
    line-height: 1.25;
    margin: 0 auto 20px;

    & span {
      color: #aaafb4;
      font-size: 14px;
      font-weight: $font--normal;
    }

    &:not(:first-child) {
      margin-top: 30px;
    }
  }

   &__personal-info {
    flex: 0 1 640px;
    margin-bottom: 10px;
  }

  &__personal-fields {
    border: 1px solid #d8dfe6;
    border-radius: 12px;
    padding: 25px 35px 20px 25px;
  }

  &__row {
    margin-bottom: 15px;
    width: 100%;

    & label {
      color: $color-dark-blue;
      font-size: 16px;
      line-height: 1.875;
      max-width: 175px;
    }

    & .input,
    & select {
      float: right;
      text-align: left;
      width: 340px;

      &::-webkit-input-placeholder {
        color: #aaafb4;
      }
      &::-moz-placeholder {
        color: #aaafb4;
      }
      &:-ms-input-placeholder {
        color: #aaafb4;
      }

      &:focus {
        border: 2px solid #b6bec6;
      }
    }

    & .input--date {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2015.9%2016%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%23B6BEC6%22%3E%3Cpath%20d%3D%22M3.5%2C6h3.2v2.4H3.5V6z%20M3.5%2C10.1h3.2v2.4H3.5V10.1z%20M9.2%2C10.1h3.2v2.4H9.2V10.1z%20M9.2%2C6%0A%09h3.2v2.4H9.2V6z%20M14.7%2C1.5h-2.2v-1C12.5%2C0.2%2C12.3%2C0%2C12%2C0s-0.5%2C0.2-0.5%2C0.5v1h-3v-1C8.5%2C0.2%2C8.3%2C0%2C8%2C0S7.5%2C0.2%2C7.5%2C0.5v1h-3v-1%0A%09C4.5%2C0.2%2C4.3%2C0%2C4%2C0S3.5%2C0.2%2C3.5%2C0.5v1H1.3C0.6%2C1.5%2C0%2C2.1%2C0%2C2.8v11.8C0%2C15.3%2C0.5%2C16%2C1.3%2C16l0%2C0h13.3c0.7%2C0%2C1.3-0.6%2C1.3-1.3V2.8%0A%09c0.1-0.6-0.4-1.2-1.1-1.3H14.7z%20M15%2C14.7c0%2C0.1-0.1%2C0.3-0.2%2C0.3h-0.1H1.3C1.2%2C15%2C1%2C14.9%2C1%2C14.8v-0.1V2.8c0-0.1%2C0.1-0.3%2C0.2-0.3h0.1%0A%09h2.2v1C3.5%2C3.8%2C3.7%2C4%2C4%2C4s0.5-0.2%2C0.5-0.5v-1h3v1C7.5%2C3.8%2C7.7%2C4%2C8%2C4s0.5-0.2%2C0.5-0.5v-1h3v1C11.5%2C3.8%2C11.7%2C4%2C12%2C4s0.5-0.2%2C0.5-0.5%0A%09v-1h2.2c0.1%2C0%2C0.3%2C0.1%2C0.3%2C0.2v0.1V14.7L15%2C14.7z%22/%3E%3C/svg%3E');
      background-position: 315px 50%;
      background-repeat: no-repeat;
    }

    &.has-error .input:not(.input--date) {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2021.0.0%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22%u0421%u043B%u043E%u0439_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20width%3D%2214%22%20height%3D%2214%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text/css%22%3E%0A%09.st0%7Bfill%3A%23F65516%3B%7D%0A%3C/style%3E%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M13.5%2C11C13.5%2C11%2C13.5%2C11%2C13.5%2C11l-4-4l4-4c0.7-0.7%2C0.6-1.8%2C0-2.5c-0.7-0.7-1.8-0.6-2.5%2C0l-4%2C4l-4-4%0A%09c-0.7-0.7-1.8-0.7-2.5%2C0C0.2%2C0.8%2C0%2C1.3%2C0%2C1.7C0%2C2.2%2C0.2%2C2.6%2C0.5%2C3l4%2C4l-4%2C4c-0.7%2C0.7-0.7%2C1.8%2C0%2C2.5C0.8%2C13.8%2C1.3%2C14%2C1.7%2C14%0A%09c0%2C0%2C0%2C0%2C0%2C0c0.5%2C0%2C0.9-0.2%2C1.2-0.5l4-4l4%2C4c0.3%2C0.3%2C0.8%2C0.5%2C1.2%2C0.5h0c0.5%2C0%2C0.9-0.2%2C1.2-0.5C14.2%2C12.8%2C14.2%2C11.7%2C13.5%2C11z%22/%3E%0A%3C/svg%3E%0A');
      background-position: 315px 50%;
      background-repeat: no-repeat;
      border-color: #ff3709;
      color: #ff3709;
    }

    &.has-success .input:not(.input--date) {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2021.0.0%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22%u0421%u043B%u043E%u0439_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2016.9%2013%22%20style%3D%22enable-background%3Anew%200%200%2016.9%2013%3B%22%20width%3D%2217%22%20height%3D%2213%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text/css%22%3E%0A%09.st0%7Bfill%3A%2376B345%3B%7D%0A%3C/style%3E%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M16.4%2C0.5C16.1%2C0.2%2C15.7%2C0%2C15.3%2C0c0%2C0%2C0%2C0%2C0%2C0c-0.4%2C0-0.8%2C0.2-1.1%2C0.5L5.5%2C9.2L2.7%2C6.8C2.4%2C6.5%2C2%2C6.3%2C1.5%2C6.3%0A%09c-0.4%2C0-0.8%2C0.2-1.1%2C0.5c-0.6%2C0.6-0.6%2C1.6%2C0.1%2C2.2c0%2C0%2C0.1%2C0.1%2C0.1%2C0.1l4%2C3.5c0.3%2C0.3%2C0.7%2C0.4%2C1%2C0.4c0.4%2C0%2C0.8-0.2%2C1.1-0.5l9.7-9.8%0A%09C17%2C2.1%2C17%2C1.1%2C16.4%2C0.5z%22/%3E%0A%3C/svg%3E%0A');
      background-position: 315px 50%;
      background-repeat: no-repeat;
      border-color: transparent;
      font-weight: $font--bold;
    }
  }

  &__sms-btn {
    background-color: rgb(253, 214, 85);
    border-radius: 8px 0 0 8px;
    color: $color-dark-blue;
    font-family: $font-sans-caption;
    font-size: 14px;
    font-weight: 700;
    height: 36px;
    line-height: 36px;
    margin-bottom: 15px;
    padding: 0 35px;
  }

  .registration &__sms-input {
    border-color: #d8dfe6 #d8dfe6 #d8dfe6 transparent;
    border-radius: 0 8px 8px 0;
    color: $color-text--base;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    margin-bottom: 15px;
    margin-left: -5px;
    min-width: 240px;
    padding: 0 5px;
    text-align: center;

    &::-webkit-input-placeholder {
      color: #aaafb4;
    }
    &::-moz-placeholder {
      color: #aaafb4;
    }
    &:-ms-input-placeholder {
      color: #aaafb4;
    }
  }

  &__btn {
    background-color: rgb(253, 214, 85);
    border-radius: 8px;
    color: $color-dark-blue;
    display: block;
    font-family: $font-sans-caption;
    font-size: 14px;
    font-weight: 700;
    height: 36px;
    line-height: 36px;
    margin: 35px auto 40px;
    text-align: center;
    width: 220px;

    &:hover {
      box-shadow: 0 0.3rem 0.5rem 0 rgba(253, 214, 85, 0.5);
    }
  }

  &__enter-btn {
    display: block;
    max-width: 200px;
    margin: 15px auto;

    &:active {
      box-shadow: none;
    }
  }

  &__error-mess {
    background-color: #cf240d;
    border-radius: 6px;
    color: #fff;
    display: none;
    max-width: 350px;
    padding: 13px 15px;
    position: relative;

    &[x-placement^="right"] {
      margin-left: 20px;
      top: -6px !important;
    }

    &[x-placement^="top"] {
      margin-top: -10px;
    }

    &[x-placement^="bottom"] {
      margin-top: 10px;
    }

    &[x-placement^="right"]:before {
      border: 8px solid transparent;
      border-right: 13px solid #cf240d;
      content: "";
      left: -21px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &[x-placement^="top"]:before {
      border: 8px solid transparent;
      border-top: 8px solid #cf240d;
      content: "";
      left: 50%;
      position: absolute;
      bottom: -15px;
      transform: translateX(-50%);
     }

    &[x-placement^="bottom"]:before {
      border: 8px solid transparent;
      border-bottom: 8px solid #cf240d;
      content: "";
      top: -15px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .registration__row.has-success & {
      display: none !important;
    }
  }

  &__common-info {
    border: 1px solid transparent;
    border-radius: 12px;
    flex: 0 1 400px;
    padding: 40px 50px 45px 50px;
    margin: 120px 0 auto;
    background-color: rgba(235, 241, 246, 0.5);

    & h2 {
      color: $color-icon--accent;
      font-family: $font-stack-base;
      font-size: 16px;
      font-weight: $font--bold;
      line-height: 1.5;
      margin: 0 0 15px;
    }

    & p {
      color: $color-icon--accent;
      font-size: 16px;
      line-height: 1.5;
      margin: 0 0 10px;
    }
  }

  &__advantages {
    margin-bottom: 30px;

    ul {
      margin: 0;
      padding-left: 16px;
    }

    li {
      margin-bottom: 10px;
    }
  }

  &__contacts {
    & .link--phone {
      color: $color-icon--accent;
      display: block;
      font-size: 30px;
      padding-left: 35px;
      position: relative;
      text-decoration: none;

      &:hover {
        color: #0077f5;
      }
    }

    .link--phone .svg-icon {
      position: absolute;
      left: 0;
      transform: translateY(-50%);
      top: 50%;
      fill: #ffc400;
    }
  }

  &__note {
    color: rgba(33, 43, 68, 0.6);
    display: block;
    font-size: 12px;
    line-height: 1.667;
    padding-left: 35px;
  }
}

.p-wrapper--registration {
  min-width: 300px;
}

.p-header--registration {
  min-width: 300px
}

.p-header--registration ~ .p-footer {
  min-width: 300px;
}

@media(max-width: 1040px) {
  .registration__personal-info {
    margin: 0 auto 10px;
  }

  .registration__common-info {
    margin: 20px auto auto;
  }

}

@media(max-width: 1000px) {
  .p-header__epigraph {
    display: none;
  }
}

@media(max-width: 700px) {
  .p-header__phone {
    display: none;
  }

  .p-header--registration .lang-switcher{
    float: none;
    margin-left: auto;
    margin-right: 0;
  }
}

@media(max-width: 550px) {
  .registration__row {
    text-align: center;
  }

  .registration__row label{
    width: 100%;
  }

  .registration__row .input {
    float: none;
    width: 100%;
  }
  .registration__row.country-row .input {
    text-align: center;
    width: 100%;
  }

  .intl-tel-input.allow-dropdown {
    width: 100%;
  }

  .intl-tel-input.allow-dropdown input {
    width: 100%;
  }

  .flag-container input {
    width: 100%
  }

  .registration__row.has-error .input:not(.input--date),
  .registration__row.has-success .input:not(.input--date),
  .registration__row .input--date {
    background-position: 98% 50%;
  }

  .registration__sms-btn {
    border-radius: 8px;
  }

  .registration .registration__sms-input {
    border-color: #d8dfe6;
    border-radius: 8px;
  }

  .registration__common-info {
    padding: 15px 10px;
  }
}

@media(max-width: 500px) {
  .p-footer__bottom,.p-footer__top {
    flex-wrap: wrap;
  }

  .p-footer__phone {
    margin-bottom: 10px;
    width: 100%;
  }

  .p-footer__social-item {
    margin: 0 10px;
  }
}

@media(max-width: 350px) {
  .p-header .btn.btn--solid {
    display: none;
  }

  .registration__personal-fields {
    padding: 15px 5px;
  }
}

.p-wrapper--registration .content-inner {
  min-width: 300px;
  width: 300px;
}

.p-wrapper--registration .p-content {
  padding: 20px 0;
}
