/**
 * 1. Set up a decent box model on the root element
 */

/*html*/.root {
  background-color: #fff;;
  box-sizing: border-box; /* 1 */
  font-size: $font--root;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  height: 100%;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */

*, *::before, *::after {
  box-sizing: inherit;
}

*:hover,
*:active,
*:focus {
  outline: none;
}

/*body*/.page {
  background-color: $color-bg--page;
  color: $color-text--base;
  font-family: $font-stack-base;
  font-size: $font--md;
  font-style: normal;
  font-weight: $font--normal;
  height: 100%;
  line-height: $lh--base;
  margin: 0;
  overflow: auto;
  padding: 0;
}
