.bankcard-help-info {
  & > h2 {
    margin: 0 0 15px;
  }

  & > p {
    margin: 0 0 20px;
  }

  & > img {
    display: block;
    margin-left: -10px;
    width: calc(100% + 10px);
  }
}
