.account-action-modal {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 24px 0 rgba(18, 47, 91, 0.25);
  padding: 0;
  width: 580px;

  & label {
    display: inline-block;
    font-size: $font--lg;
    font-weight: $font--bold;
    vertical-align: middle;
  }

  & input[type="text"] {
    height: 34px;
    font-size: $font--xm;
    text-align: left;
  }

  & .select2-selection--single {
    height: 34px;

    & .select2-selection__rendered {
      font-size: $font--xm;
      line-height: 34px;
      text-align: left;
    }

    & .select2-selection__placeholder {
      font-size: $font--xm;
    }

    & .select2-selection__arrow b {
      margin-top: -2px;
    }
  }
}

.account-action-modal__tabs {
  & .tabs__options {
    border-bottom: 1px solid #e7eaec;
    height: 61px;

    &:after {
      clear: both;
      content: "";
      display: table;
    }

    & .btn {
      background-color: #f3f6f9;

      color: $color-link--base;
      float: left;
      font-size: $font--xl;
      font-weight: $font--bold;
      line-height: 60px;
      text-align: center;
      width: 50%;
    }

    & .btn:not(.is-active):hover {
      color: $color-link--accent;
    }

    & .btn:first-child {
      border-right: 1px solid #d9dcde;
      border-radius: 10px 0 0 0;
    }

    & .btn:last-child {
      border-radius: 0 10px 0 0;
    }

    & .btn.is-active {
      background-color: #eceff1;
      color: #11233f;
    }
  }

  & .tabs__item {
    position: absolute;
    left: -9999px;
    top: 0;
    opacity: 0;
    text-align: left;
    transition: opacity 0.5s ease-in-out;

    &.is-active {
      position: relative;
      left: 0;
      opacity: 1;
    }

    & > div {
      padding: 0 40px;
    }

    & .account-type {
      background-color: #f3f6f9;
      padding-top: 13px;
      padding-bottom: 13px;

      & .select2-select {
        width: 300px;
      }
    }

    & .account-balance,
    & .account-platform,
    & .account-group-info {
      border-bottom: 1px solid #e7eaec;
      padding-top: 13px;
      padding-bottom: 13px;
    }

    & .account-balance {
      & > span {
        display: inline-block;
        font-size: $font--lg;
        font-weight: $font--bold;
        vertical-align: middle;
      }
    }

    & .account-platform {
      & > a {
        border: 1px solid transparent;
        border-radius: 17px;
        color: $color-link--base;
        cursor: pointer;
        display: inline-block;
        font-size: $font--lg;
        font-weight: $font--bold;
        margin: 0;
        padding: 3px 12px;
        position: relative;
        text-align: center;
        transition: $transition--default;
        vertical-align: middle;

        & > span {
          border-bottom: 1px dotted $color-link--base;
          display: inline-block;
          vertical-align: middle;
        }

        &:hover {
          color: $color-link--accent;

          & > span {
            border-bottom-color: $color-link--accent;
          }
        }

        &.is-active {
          color: $color-text--base;
          border-color: #c0c7ce;

          & > span {
            border-bottom: none;
          }
        }
      }
    }

    & .account-name {
      & .select2-select {
        width: 200px;
      }
    }

    & .account-leverage {
      float: left;
      margin-right: 50px;

      & .select2-select {
        width: 110px;
      }
    }

    & .account-currency {
      float: left;

      & .select2-select {
        width: 95px;
      }
    }

    & .account-payment-system {
      & .select2-select {
        width: 300px;
      }
    }

    & .account-refill-sum {
      float: left;
      margin-right: 25px;

      & input {
        width: 150px;
      }
    }

    & .account-group-info__row {
      &:after {
        clear: both;
        content: "";
        display: table;
      }

      &:not(:last-child) {
       margin-bottom: 15px;
      }
    }
  }
}

.account-action-modal__footer {
  padding: 20px 0;

  & .btn {
    font-size: $font--lg;
    font-weight: $font--bold;
    padding: 7px 40px 6px;
  }

  & .btn + .btn {
    margin-left: 45px;
  }
}
