.data-table-filters {
  margin-bottom: 10px;
  min-height: 24px;
  position: relative;

  &__trigger {
    border: 1px solid #c0c7ce;
    border-radius: 12px;
    color: #c0c7ce;
    height: 24px;
    padding: 0 13px;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 0;

    &:hover {
      border-color: #212b44;
      color: #212b44;
    }
  }
}
