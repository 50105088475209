.p-widgets-wrapper {
  margin-left: -10px;
  margin-right: -10px;

  &::before,
  &::after {
    content: "";
    display: table;
  }

  &::after {
    clear: both;
  }
}
