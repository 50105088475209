.arrow {
  border-style: solid;
  border-width: 5px;
  display: inline-block;
  height: 0;
  margin: 5px;
  vertical-align: middle;
  width: 0;

  &--up {
    border-top: 0;
    border-bottom-color: #000;
    border-left-color: transparent;
    border-right-color: transparent;
  }

  &--right {
    border-right: 0;
    border-left-color: #000;
    border-bottom-color: transparent;
    border-top-color: transparent;
  }

  &--left {
    border-left: 0;
    border-right-color: #000;
    border-bottom-color: transparent;
    border-top-color: transparent;
  }

  &--down {
    border-bottom: 0;
    border-top-color: #000;
    border-left-color: transparent;
    border-right-color: transparent;
  }
}
