.contact-info {
  background-color: #ebf1f6;
  border-radius: 6px;
  padding: 20px 20px 15px;
  width: 340px;

  & > h2 {
    font-size: $font--xl;
    margin: 0 0 25px;
  }

  & > p {
    margin: 0;

    &:not(:last-child) {
      margin: 0 0 10px;
    }

    & > a {
      color: $color-text--base;
      font-size: $font--lg;
      font-weight: $font--bold;
      margin-right: 5px;
      text-decoration: none;

      & + span {
        color: #adb5bb;
        font-style: italic;
      }
    }
  }
}
