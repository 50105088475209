@import "variables";

.select2-container--bcs {
  position: relative;

  @import "single";
  @import "multiple";

  .select2-selection--multiple {
    min-height: 30px;
  }

  .select2-search--dropdown {
    display: none;

    .select2-search__field {
      border: 1px solid $border-color;
      outline: 0;
    }
  }

  .select2-search--inline {
    display: none;

    .select2-search__field {
      outline: 0;
      box-shadow: none;
    }
  }

  .select2-selection--multiple .select2-search--inline {
    display: inline-block;
    text-align: center;
    float: none;

    & input {
      text-align: center;
      user-select: none;
      -webkit-user-select: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 95%;
      display: block;

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: #aaafb4;
        font-size: 14px;
        text-align: left;
        font-family: "PT Sans", Helvetica, Arial sans-serif;
      }
    }

    & .select2-search__field {
      margin-top: 0;
      background-color: transparent;
      font-size: 14px;
    }
  }

  .select2-dropdown {
    background-color: $dropdown-bg-color;
    border: 1px solid $border-color;
    border-radius: 18px;
    display: none;
  }

  &.select2-container--open {
    & .select2-dropdown.select2-dropdown--above {
      border-radius: 8px 8px 0 0;
      border-bottom: none;
      display: block;
      left: 0;
      overflow: hidden;
      z-index: 10000;
    }
  }

  &.select2-container--open {
    & .select2-dropdown.select2-dropdown--below {
      border-radius: 0 0 8px 8px;
      border-top: none;
      display: block;
      left: 0;
      overflow: hidden;
      z-index: 10000;
    }
  }

  .select2-results > .select2-results__options {
    max-height: $results-max-height;
    overflow-y: auto;
  }

  .select2-results__option {
    font-family: $font-stack-base;
    font-size: $font--md;
    font-weight: $font--normal;
    padding: 5px 12px;
    white-space: nowrap;

    &:last-child {
      border-bottom: 0;
    }

    &[role=group] {
      padding: 0;
    }

    &[aria-disabled=true] {
      color: $results-choice-fg-unselectable-color;
    }

    &[aria-selected=true] {
      background-color: $results-choice-bg-hover-color;
      color: $results-choice-fg-hover-color;
    }
  }

  .select2-results__option--highlighted {
    background-color: $results-choice-bg-hover-color;
    color: $results-choice-fg-hover-color;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }

  &:focus {
    outline: 0;
  }

  & .select2-selection__choice {
    float: left;
  }

  & .select2-selection {
    background-color: #fff;
    border: 1px solid #d8dfe6;
    border-radius: 8px;
    height: 100%;
    outline: 0;

    &:focus {
      border: 1px solid #d8dfe6;
      outline: 0;
    }

    & .select2-selection__choice__remove {
      margin-right: 10px;
    }

    & .select2-selection__rendered {
      font-size: 14px;
      padding-left: 12px;
      padding-right: 15px;
      margin-bottom: 0;
      text-align: left;
      display: block;
    }

    & .select2-selection__clear {
      color: #c0c7ce;
      margin-right: 5px;
      cursor: pointer;
      float: right;
      font-weight: bold;
    }

    .select2-selection__placeholder {
      color: #aaafb4;
      font-size: 14px;
    }

    .select2-selection__arrow {
      background-color: transparent;
      border: none;
      display: block;
      height: 28px;
      position: absolute;
      top: 1px;
      right: 2px;
      width: 20px;

      & b {
        display: block;
        height: 10px;
        left: 50%;
        margin-left: -4px;
        margin-top: -5px;
        position: absolute;
        top: 50%;
        width: 8px;

        &:before, &:after {
          content: '';
          border-style: solid;
          display: block;
          height: 0;
          position: absolute;
          width: 0;
        }

        &:before {
          border-color: transparent transparent #c0c7ce transparent;
          border-width: 0 3px 4px 3px;
          top: 0;
          left: 0;
        }

        &:after {
          border-color: #c0c7ce transparent transparent transparent;
          border-width: 4px 3px 0 3px;
          bottom: 0;
          left: 0;
        }
      }

    }

  }

}

.select2-container--above.select2-container--open {
  & .select2-selection--single {
    border-top: none;
    border-bottom: 1px solid $border-color;
    border-radius: 0 0 8px 8px;

    .select2-selection__arrow {
      display: none;
    }
  }
}

.select2-container--below.select2-container--open {
  & .select2-selection--single {
    border-bottom: none;
    border-top: 1px solid $border-color;
    border-radius: 8px 8px 0 0;

    .select2-selection__arrow {
      display: none;
    }
  }
}

.select2-container--above.select2-container--open {
  & .select2-selection--multiple {
    border-top: none;
    border-bottom: 1px solid $border-color;
    border-radius: 0 0 8px 8px;

    .select2-selection__arrow {
      display: none;
    }
  }
}

.select2-container--below.select2-container--open {
  & .select2-selection--multiple {
    border-bottom: none;
    border-top: 1px solid $border-color;
    border-radius: 8px 8px 0 0;

    .select2-selection__arrow {
      display: none;
    }
  }
}

.select2-container--bcs .select2-selection__choice {
  float: left;
}

.select2-container--bcs .select2-selection__choice:nth-child(2) {
  width: 95%;
  overflow: hidden;
}
