.aside-form {
  background-color: #f0f4f7;
  border: 1px solid #d8dfe6;
  border-radius: 6px;
  position: relative;
  width: 340px;

  & > h2 {
    margin: 20px 0 0;
    text-align: center;
  }

  & > form {
    margin: 0;
    padding: 0;
  }

  & input[type=text],
  & select {
    width: 100%;
  }

  &__header {
    background-color: #e7ebef;
    border-bottom: 1px solid #d8dfe6;
    min-height: 55px;
    padding: 13px 0;

    & > div {
      margin: 0 auto;
      width: 220px;
    }
  }

  &__body {
    padding: 20px 0;

    & > h4 {
      color: #a6acb0;
      font-size: $font--sm;
      font-weight: $font--semi-bold;
      margin: 0 0 10px;
      text-align: center;
      text-transform: uppercase;
    }

    & > div {
      margin: 0 auto;
      width: 220px;
    }

    & > div:not(:last-child) {
      margin-bottom: 10px;

      & + h4 {
        margin-top: 15px;
      }
    }

    & > div.u-double-margin:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__footer {
    background-color: #fff;
    border-radius: 0 0 6px 6px;
    height: 65px;
    position: relative;

    & > .btn[type=submit] {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__subnote {
    color: #a2a7ad;
    display: block;
    font-style: italic;
    margin-bottom: 5px;
    padding: 0 15px;
    white-space: nowrap;
  }

  &__checkbox {
    margin-bottom: 5px;
    text-align: center;
  }

  & + div {
    margin-top: 20px;
  }

  &--extended {
    .aside-form__header,
    .aside-form__body {
      & > div {
        width: 300px;
      }
    }
  }

  &--tabs {
    border: none;

    & > h2 {
      left: 50%;
      margin: 0;
      position: absolute;
      top: -45px;
      transform: translateX(-50%);
      width: 100%;
    }

    .aside-form__tab-switch {
      display: table;
      table-layout: fixed;
      overflow: hidden;

      & > .btn {
        background-color: #fff;
        border: 1px solid #d8dfe6;
        color: $color-link--base;
        display: table-cell;
        font-size: $font--md;
        padding: 5px 10px;
        position: relative;
        transition: $transition--default;
        width: 112px;

        & > span {
          border-bottom: 1px dotted $color-link--base;
          display: inline;
          vertical-align: middle;
          white-space: normal;
        }

        &:hover {
          color: $color-link--accent;

          & > span {
            border-bottom-color: $color-link--accent;
          }
        }

        &:nth-child(1) {
          border-radius: 6px 0 0 0;
          border-right: none;
          width: 114px;
        }

        &:nth-child(3) {
          border-left: none;
          border-radius: 0 6px 0 0;
          width: 114px;
        }

        &.is-active {
          background-color: #122f5b;
          border-color: #122f5b;
          border-bottom-color: #d8dfe6;
          color: #fff;

          &:nth-child(2) {
            border-left-color: #d8dfe6;
            border-right-color: #d8dfe6;
          }

          & > span {
            border-bottom-color: transparent;
          }
        }
      }
    }

    .aside-form__tab-content-item {
      border: 1px solid #d8dfe6;
      border-top: none;
      border-radius: 0 0 6px 6px;
      left: -9999px;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 0.5s ease-in-out;

      &.is-active {
        left: 0;
        opacity: 1;
        position: relative;
      }
    }
  }
}
