.select2-selection--multiple {
  background-color: #fff;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  outline: 0;
  overflow: hidden;

  &:focus {
    border: 1px solid $focus-border-color;
    outline: 0;
  }

  .select2-selection__rendered {
    font-size: $font--md;
    line-height: 30px;
    list-style: none;
    margin: 0;
    min-width: 98%;
    padding-left: 8px;
    position: relative;
  }

  .select2-selection__clear {
    color: #98a3af;
    cursor: pointer;
    position: absolute;
    font-weight: bold;
    right: 5px;
  }

  .select2-selection__placeholder {
    color: #aaafb4;
    font-size: $font--md;
  }

  .select2-selection__arrow {
    background-color: transparent;
    border: none;
    display: block;
    height: 28px;
    position: absolute;
    top: 1px;
    right: 2px;
    width: 20px;

    b {
      display: block;
      height: 10px;
      left: 50%;
      margin-left: -4px;
      margin-top: -5px;
      position: absolute;
      top: 50%;
      width: 8px;

      &::before,
      &::after {
        content: "";
        border-style: solid;
        display: block;
        height: 0;
        position: absolute;
        width: 0;
      }

      &::before {
        border-color: transparent transparent #c0c7ce transparent;
        border-width: 0 3px 4px 3px;
        top: 0;
        left: 0;
      }

      &::after {
        border-color: #c0c7ce transparent transparent transparent;
        border-width: 4px 3px 0 3px;
        bottom: 0;
        left: 0;
      }
    }
  }
}

&.select2-container--open {
  .select2-selection--multiple {
    border-bottom: none;
    border-radius: 8px 8px 0 0;

    .select2-selection__arrow {
      display: none;
    }
  }
}

&.select2-container--focus {
  .select2-selection--multiple{
    box-shadow: 0 2px 6px 0 rgba(18, 47, 91, 0.06);
  }
}

&.select2-container--disabled {
  .select2-selection--multiple {
    background-color: #f5f6f7;
    cursor: not-allowed;
    opacity: 0.8;
  }
}

.select2-selection__choice {
  display: inline-block;
  margin-right: 10px;
}
