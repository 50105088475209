.main-banner {
  background-color: #f4f8fb;
  border-radius: 8px;
  height: 150px;
  overflow: hidden;
  margin-bottom: 20px;
  table-layout: fixed;
  width: 100%;
  position: relative;

  &__title {
    color: rgba(255, 255, 255, 0.9);
    font-family: $font-sans-caption;
    font-size: 30px;
    font-weight: $font--bold;
    margin: 0 0 10px;
    flex: 1 0 100%;
  }

  &__img {
    height: 100%;
    width: 100%;
  }

  &__info {
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    left: 0;
    padding: 30px 20px 20px 30px;
    position: absolute;
    top: 0;
    width: 100%;

    & p {
      line-height: 1.375;
      margin: 0;
    }
  }

  &__link {
    border: 1px solid #fff;
    border-radius: 8px;
    font-family: $font-sans-caption;
    font-size: 14px;
    color:#fff;
    display: block;
    margin: auto 0 0 auto;
    padding: 10px 38px 9px;

    &:hover, &:focus {
      border-color: transparent;
      color: #2b2e38;
    }
  }
}
