.error-page {
  margin-top: 160px;
  margin-left: 280px;

  &__code {
    color: #0077f5;
    font-weight: $font--bold;
    font-size: 250px;
    line-height: 1;
    margin: 0;
    opacity: 0.1;
    text-align: center;
  }

  &__description {
    color: #d0e0fc;
    font-size: 18px;
    margin: 0 100px 120px 0;
    text-align: right;
  }

  &__back {
    text-align: center;
    display: block;
    max-width: 230px;
    margin: 0 auto;
    padding: 6px 25px 5px 50px;
  }
}