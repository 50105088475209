.data-tabs {
  margin: 0;
  min-height: 55px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &__options {
    font-size: $font--lg;
    line-height: 1;
    margin-bottom: 15px;

    & > span {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
    }

    & > ul {
      display: inline-block;
      list-style: none;
      padding: 0;
      margin: 0;
      vertical-align: middle;

      & > li {
        border: 1px solid transparent;
        border-radius: 17px;
        color: $color-link--base;
        cursor: pointer;
        display: inline-block;
        font-weight: $font--bold;
        vertical-align: top;
        margin: 0;
        padding: 8px 12px 7px;
        position: relative;
        text-align: center;
        transition: $transition--default;

        & > span {
          border-bottom: 1px dotted $color-link--base;
          display: inline-block;
          vertical-align: middle;
        }

        &:hover {
          color: $color-link--accent;

          & > span {
            border-bottom-color: $color-link--accent;
          }
        }

        &.is-active {
          color: $color-text--base;
          border-color: #c0c7ce;

          & > span {
            border-bottom: none;
          }
        }
      }
    }
  }

  &__content-item {
    position: absolute;
    left: -9999px;
    top: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.is-active {
      position: relative;
      left: 0;
      opacity: 1;
      min-height: 405px;
    }
  }
}
