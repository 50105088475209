$remove-color: #888;
$remove-hover-color: #555;
$remove-width: 20px;

$selection-color: $color-text--base;

$border-color: #d8dfe6;
$border-radius: 8px;

$focus-border-color: #d8dfe6;

$container-height: 30px;

$selection-bg-top-color: white;
$selection-bg-bottom-color: #eeeeee;

$container-placeholder-color: #999;

$container-focus-border-color: blue;

$selection-opened-bg-top-color: $selection-bg-bottom-color;
$selection-opened-bg-bottom-color: $selection-bg-top-color;

$dropdown-z-index: 1;

$dropdown-bg-color: $selection-bg-top-color;

$results-max-height: 230px;
$results-nested-padding: 20px;

$results-choice-bg-hover-color: #ebf1f6;
$results-choice-fg-hover-color: $color-text--base;

$results-choice-fg-unselectable-color: grey;
