.data-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  font-size: $font--xm;
  width: 100%;

  th {
    padding: 0 5px;
    text-align: left;

    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }
  }

  td {
    background-color: #eef2f6;
    height: 50px;
    padding: 5px;
    position: relative;

    &:after {
      background-color: #eef2f6;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: $transition--default;
      width: 100%;
      z-index: -1;
    }

    &:first-child {
      border-radius: 6px 0 0 6px;
      padding-left: 10px;

      &:after {
        border-radius: 6px 0 0 6px;
      }
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
      padding-right: 10px;

      &:after {
        border-radius: 0 6px 6px 0;
      }
    }

    & > div {
      display: block;
      height: 100%;
      overflow: hidden;
      padding: 0;
      position: relative;
      width: 100%;

      & > p {
        display: block;
        margin: 0;
        padding: 0;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  tr:hover {
    td:after {
      box-shadow: 0 0 1px 0 rgba(17, 35, 63, 0.35);
    }
  }

  &__subnote {
    color: #98a3af;
    display: block;
    font-size: $font--sm;
  }

  &__sort-btn {
    color: #7cabd5;
    display: inline-block;
    font-size: $font--sm;
    margin-bottom: -1px; // to remove the space left by border-spacing
    position: relative;
    text-transform: uppercase;

    &:after {
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 3px solid #7cabd5;
      content: "";
      display: block;
      height: 0;
      position: absolute;
      right: -10px;
      top: 50%;
      transform: translateY(-50%);
      transition: $transition--default;
      width: 0;
    }

    &:hover {
      color: #5aa5f3;

      &:after {
        border-top-color: #5aa5f3;
      }
    }

    &.is-active {
      &:after {
        border-top: 0;
        border-bottom: 3px solid #5aa5f3;
      }
    }
  }

  .btn--ghost {
    padding-left: 13px;
    padding-right: 13px;
  }

  .data-table-wrapper > & {
    margin: -10px 0; // to remove the space left by border-spacing
  }
}
