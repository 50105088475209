.browser-upgrade {
  background-color: #f25648;
  color: #fff;
  font-size: $font--md;
  height: 40px;
  line-height: 1;
  left: 0;
  margin: 0;
  padding-top: 15px;
  position: fixed;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 100%;
  z-index: 99999;

  &__link {
    @include on-event() {
      color: #fff;
      text-decoration: none;
    }

    color: #fff;
    text-decoration: underline;
  }
}



