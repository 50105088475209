/**
 * Font families
 * @type List
 */
$font-stack-base: 'PT Sans', Helvetica, Arial sans-serif !default;
$font-sans-caption: 'PT Sans Caption', Helvetica, Arial sans-serif !default;

/**
 * Font-sizes
 * @type Length
 */
$font--root: 10px !default;
$font--xs: 10px !default;
$font--sm: 12px !default;
$font--md: 14px !default;
$font--xm: 16px !default;
$font--lg: 18px !default;
$font--xl: 20px !default;
$font--xxl: 22px !default;

// Project specific font sizes
$font--main-heading: 24px;

/// Font weights
/// @type Length
$font--light: 300 !default;
$font--normal: 400 !default;
$font--semi-bold: 500 !default;
$font--bold: 700 !default;

/**
 * Colors
 * @type Color
 */
$color-brand: #fff !default;

$color-yellow--base: #fdd655 !default;
$color-dark-blue: #122f5b !default;

/* text */
$color-text--base: #2a2a2a !default;

/* links */
$color-link--base: #0077f5 !default;
$color-link--accent: #5aa5f3 !default;

/* icons */
$color-icon--base: #98a3af !default;
$color-icon--accent: #212b44 !default;

/* background */
$color-bg--page: #fff !default;
$color-bg--wrapper: #fff !default;
$color-bg--sidebar: #eff1f3 !default;

/* borders */
$color-border--base: #fff !default;

/* statuses */
$color-status--success: #76b345 !default;
$color-status--error: #f65516 !default;
$color-status--warning: $color-yellow--base !default;


/**
 * Default line-height
 * @type length
 */
$lh--base: 1.428571429; /* 20/14 */

/**
 * Base element widths
 * @type length
 */
$p_header_height: 80px;

/**
 * Base grid width
 * @type length
 */
$width--base: 960px !default;
$width--min: 1035px !default;
$width--max: 1440px !default;

/**
 * Transitions
 * @type property values
 */
$transition--default: all 0.2s ease-out;

/**
 * Relative or absolute URL where all assets are served from
 * @type String
 * @example scss - When using a CDN
 * $base-url: "http://cdn.example.com/assets/";
 */
$base-url: "./" !default;
