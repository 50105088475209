.inner-row {
  width: 200px;

  & select {
    width: 100%;
  }

  &.has-error .input:not(.input--date) {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2021.0.0%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22%u0421%u043B%u043E%u0439_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2014%2014%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%3B%22%20width%3D%2214%22%20height%3D%2214%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text/css%22%3E%0A%09.st0%7Bfill%3A%23F65516%3B%7D%0A%3C/style%3E%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M13.5%2C11C13.5%2C11%2C13.5%2C11%2C13.5%2C11l-4-4l4-4c0.7-0.7%2C0.6-1.8%2C0-2.5c-0.7-0.7-1.8-0.6-2.5%2C0l-4%2C4l-4-4%0A%09c-0.7-0.7-1.8-0.7-2.5%2C0C0.2%2C0.8%2C0%2C1.3%2C0%2C1.7C0%2C2.2%2C0.2%2C2.6%2C0.5%2C3l4%2C4l-4%2C4c-0.7%2C0.7-0.7%2C1.8%2C0%2C2.5C0.8%2C13.8%2C1.3%2C14%2C1.7%2C14%0A%09c0%2C0%2C0%2C0%2C0%2C0c0.5%2C0%2C0.9-0.2%2C1.2-0.5l4-4l4%2C4c0.3%2C0.3%2C0.8%2C0.5%2C1.2%2C0.5h0c0.5%2C0%2C0.9-0.2%2C1.2-0.5C14.2%2C12.8%2C14.2%2C11.7%2C13.5%2C11z%22/%3E%0A%3C/svg%3E%0A');
    background-position: 315px 50%;
    background-repeat: no-repeat;
    border-color: #ff3709;
    color: #ff3709;
  }
}

.inner-test .page {
  background-color: #ffffff;
  color: #2a2a2a;
  font-family: "PT Sans", Helvetica, Arial sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 100%;
  line-height: 1.42857;
  margin: 0;
  overflow: auto;
  padding: 0;
}

.inner-test .p-wrapper {
  height: 100%;
  margin: 0 auto;
  overflow: visible;
  position: relative;
  width: 100%;
  min-width: 1180px;
}

.inner-test .p-header {
  background-color: #fff;
  height: 80px;
  /* left: 0; */
  min-width: 1035px;
  position: relative;
  overflow: visible;
  /* top: 0; */
  width: 100%;
  /* z-index: 10; */

  &::before {
    border-left: 9999px solid #122f5b;
    box-shadow: 9999px 0 0 #122f5b;
    bottom: 0;
    content: none;
    display: block;
    left: -9999px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  & .p-header__user-info {
    max-width: 100%;
  }
}


.inner-test .p-main {
  background-color: #fff;
  /* overflow: auto; */
  display: flex;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1;
}

.inner-test .p-sidebar {
  background-color: #eff1f3;
  height: auto;
  /* left: 0; */
  margin-bottom: 0;
  flex: none;
  overflow: hidden;
  padding-bottom: 0;
  position: relative;
  top: 0;
  transform: translateX(0);
  transition: all 0.2s ease-out;
  z-index: 1;
  width: 260px;
}

.inner-test .p-content {
  padding: 20px;
  flex: 1 0 auto;
  max-width: 1180px;
  padding-right: 0;
  width: calc(100% - 260px);
  min-width: 640px;
}

.inner-test .p-footer {
  background-color: #122f5b;
  /* bottom: 0; */
  color: #fff;
  /* left: 0; */
  overflow: visible;
  padding: 30px 40px 50px 30px;
  position: relative;
  width: 100%;
  z-index: 10;
  filter: none;

  &::before {
    border-left: 9999px solid #122f5b;
    box-shadow: 9999px 0 0 #122f5b;
    bottom: 0;
    content: none;
    left: -9999px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
}

.inner-test .page.sidebar-is-visible .p-footer {
  filter: blur(5px);
}

.inner-test .page.sidebar-is-visible .p-main {
  filter: none;
  overflow: visible;
}

.inner-test .main-banner {
  margin: -20px 0 20px -20px;
  width: calc(100% + 20px);
}

.inner-test.root {
  background-color: #fff;
}

.inner-test .p-header__extra-opts {
  background-color: #ebf1f6;
}
