.p-footer {
  background-color: #122f5b;
  color: #fff;
  overflow: visible;
  padding: 30px 40px 50px 40px;
  position: relative;
  width: 100%;
  z-index: 10;

  .p-header--registration ~ & {
    overflow: unset;
    overflow: unset;
    max-width: 1440px;
    min-width: 640px;
    margin: 0 auto;
    padding: 30px 40px 50px 40px;

    &::before{
      border-left: 9999px solid #122f5b;
      box-shadow: 9999px 0 0 #122f5b;
      bottom: 0;
      content: " ";
      left: -9999px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }

  &__about-company {
    font-size: 14px;
  }

  &__notice {
    font-size: 12px;
    text-align: left;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & p:not(:last-child) {
      margin: 0 0 5px;
    }

    & p:last-child {
      margin: 0;
    }
  }

  &__description {
    color: #98a3af;
    font-size: 12px;
    margin-bottom: 30px;

    & span {
      font-weight: $font--bold;
    }

    & p:not(:last-child) {
      margin: 0 0 27px;
      position: relative;

      &:after {
        bottom: -20px;
        color: #98a3af;
        content: "---";
        position: absolute;
        left: 0;
      }
    }

    & p {
      margin: 0;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
  }

  &__phone {
    display: block;
    font-size: 14px;
    line-height: 1.4285714;

    &:hover {
      & .svg-icon {
        fill: #fff;
      }

      & a {
        background: linear-gradient(to right, transparent 33%, transparent 0%) 0 100% repeat-x;
      }
    }

    & .svg-icon {
      fill: #98a3af;
      margin-right: 10px;
    }

    & a {
      background: linear-gradient(to right, #98a3af 33%, rgba(255, 255, 255, 0) 0%) 0 100% repeat-x;
      background-size: 3px 1px;
      color: #fff;
      text-decoration: none;
      transform: $transition--default;
    }
  }

  &__social-list {
    list-style: none;
    margin: 0 -15px;
    padding: 0;
  }

  &__social-item {
    font-size: 0;
    display: inline-block;
    margin: 0 15px;

    & .svg-icon {
      fill: #98a3af;

      &:hover {
        fill: #fff;
      }
    }
  }
}