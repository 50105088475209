.user-support {
  background-size: 20px 15px;
  border: 1px solid #d1d6da;
  border-radius: 15px;
  position: relative;
  display: inline-block;
  fill: $color-icon--base;
  vertical-align: top;
  height: 30px;
  margin: 0;
  padding: 0 5px;
  text-align: center;


  &::before {
    background-color: $color-icon--base;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 6px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 6px;
  }

  &.is-offline {
    cursor: default;

    &::before {
      background-color: #eb533f;
    }
  }

  &.is-online {
    &::before {
      background-color: #76b345;
    }

    &:hover, &:focus {
      background-color: $color-yellow--base;
      border-color: transparent;
      fill: #212b44;
    }
  }

  .p-header__user-info > & {
    float: right;
    margin-left: 3%;
    top: 50%;
    transform: translateY(-50%);
  }

}
