.main-menu {
  background-color: $color-bg--sidebar;
  list-style: none;
  margin: 5px 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;

  & > li > span {
    background-color: $color-bg--sidebar;
    border-bottom: 1px solid #dee0e3;
    color: $color-text--base;
    display: block;
    font-size: $font--xm;
    font-weight: $font--bold;
    padding: 18px 5px 18px 40px;
    position: relative;
    z-index: 2;

    &:hover {
      color: $color-link--accent;
      cursor: pointer;
    }
  }

  & > .has-sub {
    position: relative;

    & > span:after {
      background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2214%22%20height%3D%2214%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22%23122F5B%22%20d%3D%22M10%208H4l3-4%203%204z%22/%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22%23122F5B%22%20d%3D%22M7%2014c-3.866%200-7-3.134-7-7s3.134-7%207-7%207%203.134%207%207-3.134%207-7%207zM7%201C3.686%201%201%203.686%201%207s2.686%206%206%206%206-2.686%206-6-2.686-6-6-6z%22/%3E%3C/svg%3E') no-repeat;
      content: "";
      display: inline-block;
      height: 14px;
      font-family: $font-stack-base;
      font-size: $font--xs;
      margin-left: 10px;
      position: relative;
      vertical-align: middle;
      transform: rotate(180deg);
      transition: all 150ms ease-in-out;
      width: 14px;
    }

    &.is-opened > span:after {
      background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2214%22%20height%3D%2214%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22%235aa5f3%22%20d%3D%22M10%208H4l3-4%203%204z%22/%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22%235aa5f3%22%20d%3D%22M7%2014c-3.866%200-7-3.134-7-7s3.134-7%207-7%207%203.134%207%207-3.134%207-7%207zM7%201C3.686%201%201%203.686%201%207s2.686%206%206%206%206-2.686%206-6-2.686-6-6-6z%22/%3E%3C/svg%3E') no-repeat;
    }

    &.is-opened {
      & > span:after {
        transform: rotate(0deg);
      }

      & > ul {
        max-height: 999px;
        transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
        transition-delay: 0s;
        visibility: visible;
      }
    }

    & > ul {
      background-color: #e8ebed;
      list-style: none;
      margin: 0;
      max-height: 0;
      overflow: hidden;
      padding: 0;
      position: relative;
      transition: all 0.9s cubic-bezier(0, 1, 0, 1) -0.1s;
      visibility: hidden;
      width: 100%;
      z-index: 1;

      a {
        border-bottom: 1px solid #dee0e3;
        color: #122f5b;
        display: block;
        padding: 9px 0 9px 40px;
        text-decoration: none;

        &:hover,
        &.is-active {
          background-color: #f4f6f8;
          color: $color-link--base;
        }

        &.is-active {
          cursor: default;
        }

        & > span {
          text-decoration: underline;
        }

        &:active > span,
        &.is-active > span {
          text-decoration: none;
        }
      }
    }
  }

}
