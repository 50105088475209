.modal-window {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 24px 0 rgba(18, 47, 91, 0.25);
  padding: 0;
  width: 580px;

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  &__header {
    border-bottom: 1px solid #e7eaec;
    font-size: 18px;
    height: 61px;
    line-height: 1;
    margin-bottom: 15px;
  }

  &__body {
    border-bottom: 1px solid #e7eaec;
    padding: 0 40px;
  }

  &__row {
    border-bottom: 1px solid #e7eaec;
    padding: 20px 0;
    width: 100%;
    text-align: left;

    &:last-child {
      border-bottom-color: transparent;
    }
  }

  & input[type=text], select {
    display: inline-block;
    margin: auto 0;
    text-align: left;
    width: 340px;
  }

  & label {
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    max-width: 150px;
    vertical-align: middle;
    width: 150px;
    text-align: left;
}

  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;

    & .btn {
      border-radius: 8px;
      font-family: $font-stack-base;
      font-size: 18px;
      font-weight: 700;
      padding: 7px 40px 6px;
    }
  }

  &--account-profile {
    & span {
      min-width: 340px;
      display: inline-block;
      text-align: center;
    }

    & .btn {
      border-radius: 8px;
    }

    & .cancel-btn {
      margin: 0 auto;
    }
  }
}
