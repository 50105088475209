.btn {
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  font-family: $font-stack-base;
  font-weight: $font--bold;
  margin: 0;
  padding: 0;
  position: relative;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  &:focus {
    outline: none;

    &:active {
      outline: none;
    }
  }

  &--ghost {
    background-color: transparent;
    border: 1px solid $color-yellow--base;
    border-radius: 25px;
    color: $color-text--base;
    fill: $color-text--base;
    font-size: $font--md;
    font-weight: $font--bold;
    padding: 6px 25px 5px;

    & > .svg-icon {
      margin-right: 10px;
    }

    &:hover, &:focus {
      background-color: $color-yellow--base;
    }
  }

  &--disabled {
    background-color: #d8dfe6;
    border: 1px solid #d8dfe6;
    border-radius: 25px;
    color: #2a2a2a;
    fill: #2a2a2a;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 25px 5px;

    & > .svg-icon {
      margin-right: 10px;
    }
  }

  &--solid {
    background-color: $color-yellow--base;
    border: 1px solid transparent;
    border-radius: 25px;
    color: $color-text--base;
    fill: $color-text--base;
    font-size: $font--md;
    font-weight: $font--bold;
    padding: 6px 25px 5px;

    & > .svg-icon {
      margin-right: 10px;
    }

    &:hover, &:focus {
      box-shadow: 0 3px 5px 0 rgba(253, 214, 85, 0.5);
    }
  }

  &--round {
    background-color: transparent;
    border: 1px solid $color-yellow--base;
    border-radius: 50%;
    fill: $color-yellow--base;
    height: 23px;
    position: relative;
    width: 23px;

    & > .svg-icon {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover, &:focus {
      background-color: $color-yellow--base;
      fill: $color-icon--accent;
    }

    &:active {
      box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    }
  }

  &--edit {
    fill: $color-icon--base;

    &:hover, &:focus {
      fill: $color-link--accent;
    }

    &:active {
      fill: $color-link--base;
    }
  }

  &--delete {
    fill: $color-icon--base;

    &:hover, &:focus {
      fill: #eb533f;
    }

    &:active {
      fill: $color-status--error;
    }
  }

  &--edit + &--delete {
    margin-left: 20px;
  }
}
