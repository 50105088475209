.payments-tabs {
  &__switch {
    display: inline-block;
    height: 26px;
    margin-right: 20px;
    overflow: hidden;
    vertical-align: middle;
    width: 130px;

    & > .btn {
      border: 1px solid #c0c7ce;
      display: block;
      fill: $color-icon--base;
      float: left;
      height: 100%;
      position: relative;
      width: 43px;

      & > .svg-icon {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:nth-child(1) {
        border-radius: 13px 0 0 13px;
        border-right: none;

        & > .svg-icon {
          height: 16px;
          width: 24px;
        }
      }

      &:nth-child(2) {
        & > .svg-icon {
          height: 16px;
          width: 24px;
        }
      }

      &:nth-child(3) {
        border-left: none;
        border-radius: 0 13px 13px 0;

        & > .svg-icon {
          height: 12px;
          width: 16px;
        }
      }

      &:hover {
        fill: $color-link--base;
      }

      &.is-active {
        background-color: #122f5b;
        border-color: #122f5b;
        fill: #fff;

        &:nth-child(2) {
          border-left-color: #c0c7ce;
          border-right-color: #c0c7ce;
        }
      }
    }
  }

  &__content-item {
    left: 0;
    min-height: 20px;
    opacity: 1;
    padding-top: 5px;
    position: relative;

    &.is-hidden {
      position: absolute;
      left: -9999px;
      opacity: 0;
      top: 0;
      transition: opacity 0.5s ease-in-out;
    }

    & > p {
      color: $color-icon--base;
      fill: $color-icon--base;
      font-size: $font--sm;
      font-weight: $font--semi-bold;
      margin: 0 0 10px;
    }

    &:not(.is-hidden) + &:not(.is-hidden) {
      margin-top: 20px;
    }

    &:not(.is-hidden):last-child {
      margin-bottom: 20px;
    }
  }

  &__reset {
    color: $color-link--base;
    border-bottom: 1px dotted $color-link--base;
    font-size: $font--sm;
    font-weight: $font--bold;

    &:hover {
      color: $color-link--accent;
      border-bottom: 1px dotted $color-link--accent;
    }

    &:active {
      border-bottom-color: transparent;
    }
  }
}
