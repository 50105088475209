.user-messages {
  background-size: 20px 15px;
  border: 1px solid #d1d6da;
  border-radius: 15px;
  position: relative;
  display: inline-block;
  fill: $color-icon--base;
  font-size: 0;
  vertical-align: top;
  height: 30px;
  margin: 0;
  padding-left: 10px;
  text-align: center;

  &[data-number = '0'] {
    padding-right: 13px;
  }

  &:not([data-number = '0']):after {
    background-color: #f15c3a;
    border-radius: 6px;
    color: #fff;
    content: attr(data-number);
    display: inline-block;
    height: 12px;
    font-size: $font--xs;
    font-weight: $font--bold;
    line-height: 12px;
    padding: 0 4px;
    position: relative;
    right: 8px;
    text-align: center;
    top: -5px;
    vertical-align: middle;
    width: auto;
  }

  &[data-number = '0']:after {
    content: none;
  }

  .ie &:after {
    line-height: 12px;
  }

  &:hover, &:focus {
    background-color: $color-yellow--base;
    border-color: transparent;
    fill: #212b44;
  }

  .p-header__user-info > & {
    float: right;
    margin-left: 3%;
    top: 50%;
    transform: translateY(-50%);
  }
}
