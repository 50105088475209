.alert {
  border-radius: 4px;
  border: 1px solid transparent;
  font-size: 14px;
  margin-bottom: 20px;
  max-width: 350px;
  padding: 15px 30px 15px 20px;
  position: fixed;
  right: 50%;
  top: 15vh;
  transform: translateX(50%);
  z-index: 11;

  &-success {
    background-color: #9bc231;
    border-color: #9bc231;
    color: #fff;
  }

  &-info {
    background-color: #2196f3;
    border-color: #2196f3;
    color: #fff;
  }
  &-warning {
    background-color: #ff9800;
    border-color: #ff9800;
    color: #fff;
  }
  &-danger {
    background-color: #cf240d;
    border-color: #cf240d;
    color: #fff;
  }

  & > .close {
    background-color: transparent;
    border: transparent;
    display: block;
    font-size: 0;
    height: 15px;
    opacity: 1;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: $transition--default;
    width: 15px;

    &:before {
      background-color: #fff;
      content: "";
      height: 13px;
      opacity: 0.5;
      position: absolute;
      top: 0;
      transform: rotate(45deg);
      transition: $transition--default;
      width: 1px;
    }

    &:after {
      background-color: #fff;
      content: "";
      height: 13px;
      opacity: 0.5;
      position: absolute;
      top: 0;
      transform: rotate(-45deg);
      transition: $transition--default;
      width: 1px;
    }

    &:hover:before,
    &:hover:after {
      opacity: 1;
    }
  }
}
