.l-two-columns {
  display: table;
  table-layout: fixed;
  width: 100%;

  & > div {
    display: table-cell;
    vertical-align: top;

    &:first-child {
      width: 100%;

      &:not(:only-child) {
        padding-right: 20px;
      }
    }

    &:last-child {
      width: 340px;
    }
  }
}
