.link {
  color: $color-link--base;
  fill: $color-link--base;
  font-weight: $font--normal;
  text-decoration: none;

  @include on-event() {
    color: $color-link--accent;
    fill: $color-link--accent;
  }

  &--pseudo {
    border-bottom: 1px dotted $color-link--base;

    @include on-event() {
      border-bottom-color: $color-link--accent;
    }
  }
}
