.nav-toggle {
  display: block;
  height: 24px;
  position: relative;
  width: 30px;

  &::before,
  &::after {
    content: "";
  }

  &::before,
  &::after,
  & > span {
    background-color: #fff;
    display: block;
    height: 4px;
    transition: $transition--default;
    width: 100%;
  }

  & > span {
    margin: 6px auto;
  }

  &.is-active::before {
    transform: translateY(10px) rotateZ(45deg);
  }

  &.is-active > span {
    width: 0;
  }

  &.is-active::after {
    transform: translateY(-10px) rotateZ(-45deg);
  }
}
