$p_widget_header_height: 35px;

.p-widget {
  border: 1px solid #d8dfe6;
  border-radius: 5px;
  display: inline-block;
  height: auto;
  float: right;
  margin: 0 10px 20px;
  max-height: 230px;
  min-width: 420px;
  width: calc(50% - 20px);
  overflow: hidden;
  position: relative;

  &::before {
    background: linear-gradient(to top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%) no-repeat;
    content: "";
    display: block;
    height: 10px;
    position: absolute;
    top: $p_widget_header_height;
    width: 100%;
    z-index: 1;
  }

  &:after {
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%) no-repeat;
    bottom: 0;
    content: "";
    display: block;
    height: 30px;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  &__header {
    height: $p_widget_header_height;
    padding: 5px 12px;
    position: relative;
    text-align: left;
    width: 100%;

    & > .drag-btn {
      fill: $color-icon--base;
      height: 10px;
      width: 5px;

      & > .svg-icon {
        display: block;
      }

      &:hover {
        cursor: move;
        fill: $color-icon--accent;
      }
    }

    & > .heading {
      display: inline-block;
      font-size: $font--xm;
      font-weight: $font--bold;
      padding: 0;
      position: relative;
      margin: 0 10px;
      vertical-align: middle;
    }

    & > .settings-btn {
      fill: $color-icon--base;
      height: 10px;
      right: 12px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;

      & > .svg-icon {
        display: block;
      }

      &:hover {
        fill: $color-icon--accent;
      }
    }
  }

  &__content {
    height: calc(100% - #{$p_widget_header_height});
    padding: 0 12px;
    overflow: auto;

    & .btn {
      font-weight: 700;
    }
  }

  &--student-accounts,
  &--trade-accounts {
    float: left;
    max-height: 310px;
  }
}
