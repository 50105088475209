h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-stack-base;
  font-weight: $font--bold;
  line-height: $lh--base;
}

h1 {
  font-size: $font--main-heading;
  line-height: 1.25 /* 30/24 */
}

h2 {
  font-size: $font--xl;
}

h3 {
  font-size: $font--lg;
}

b,
strong {
  font-family: $font-stack-base;
  font-weight: $font--bold;
  line-height: $lh--base;
}

i,
em {
  font-family: $font-stack-base;
  font-style: italic;
  font-weight: $font--normal;
  line-height: $lh--base;
}

p {
  font-family: $font-stack-base;
  font-weight: $font--normal;
  line-height: $lh--base;
}

a,
img {
  border: none;
  outline: none;
}

a,
button {
  transition: $transition--default;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

input[type=text],
select {
  background-color: #fff;
  border: 1px solid #d8dfe6;
  border-radius: 8px;
  color: $color-text--base;
  display: inline-block;
  font-family: $font-stack-base;
  font-size: $font--md;
  height: 30px;
  padding: 0 15px;
  vertical-align: middle;
  text-align: center;

  &:focus {
    box-shadow: 0 2px 6px 0 rgba(18, 47, 91, 0.06);
  }

  &[disabled] {
    background-color: #f5f6f7;
    cursor: not-allowed;
    opacity: 0.8;
  }

  &.has-error {
    border-color: $color-status--error;
  }

  &.is-valid {
    border-color: $color-status--success;
  }
}

input[type=text] {
  ::-webkit-input-placeholder {
    color: #aaafb4;
  }

  ::-moz-placeholder {
    color: #aaafb4;
  }

  :-ms-input-placeholder {
    color: #aaafb4;
  }
}

input[type=checkbox]:not(.switcher-checkbox) {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  & + label:not(:last-child) {
    margin-right: 10px;
  }

  & + label::before {
    background-color: transparent;
    border: 1px solid #98a3af;
    border-radius: 2px;
    content: "";
    cursor: pointer;
    display: inline-block;
    height: 16px;
    margin: 0 8px 0 0;
    transition: $transition--default;
    vertical-align: text-top;
    width: 16px;
  }

  &:focus + label::before {
    border-color: $color-yellow--base;
  }

  &:checked + label::before {
    background: $color-yellow--base url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%228px%22%20viewBox%3D%220%200%2012%208%22%20fill-rule%3D%22evennodd%22%20fill%3D%22%23ffffff%22%3E%0A%3Cpath%20d%3D%22M11.000%2C1.000%20L11.000%2C0.000%20L12.000%2C0.000%20L12.000%2C1.000%20L11.000%2C1.000%20ZM10.000%2C2.000%20L10.000%2C1.000%20L11.000%2C1.000%20L11.000%2C2.000%20L10.000%2C2.000%20ZM9.000%2C3.000%20L9.000%2C2.000%20L10.000%2C2.000%20L10.000%2C3.000%20L9.000%2C3.000%20ZM8.000%2C4.000%20L8.000%2C3.000%20L9.000%2C3.000%20L9.000%2C4.000%20L8.000%2C4.000%20ZM7.000%2C5.000%20L7.000%2C4.000%20L8.000%2C4.000%20L8.000%2C5.000%20L7.000%2C5.000%20ZM6.000%2C6.000%20L6.000%2C5.000%20L7.000%2C5.000%20L7.000%2C6.000%20L6.000%2C6.000%20ZM5.000%2C7.000%20L5.000%2C6.000%20L6.000%2C6.000%20L6.000%2C7.000%20L5.000%2C7.000%20ZM4.000%2C8.000%20L4.000%2C7.000%20L5.000%2C7.000%20L5.000%2C8.000%20L4.000%2C8.000%20ZM3.000%2C6.000%20L4.000%2C6.000%20L4.000%2C7.000%20L3.000%2C7.000%20L3.000%2C6.000%20ZM2.000%2C5.000%20L3.000%2C5.000%20L3.000%2C6.000%20L2.000%2C6.000%20L2.000%2C5.000%20ZM1.000%2C4.000%20L2.000%2C4.000%20L2.000%2C5.000%20L1.000%2C5.000%20L1.000%2C4.000%20ZM-0.000%2C3.000%20L1.000%2C3.000%20L1.000%2C4.000%20L-0.000%2C4.000%20L-0.000%2C3.000%20Z%22/%3E%0A%3C/svg%3E%0A') 50% 50% no-repeat;
    background-size: 75%;
    border-color: $color-yellow--base;
  }

  &[disabled] + label::before {
    background-color: #f5f6f7;
    border-color: #98a3af;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

select {
  &.has-error + .select2 + .select2-selection--single {
    border-color: $color-status--error;
  }

  &.is-valid + .select2 + .select2-selection--single {
    border-color: $color-status--success;
  }
}

.switcher-slide {
  background: #fff;
  border-radius: 13px;
  height: 26px;
  left: 37px;
  position: absolute;
  top: 2px;
  transition: left 0.4s ease, background 0s ease;
  width: 34px;
}

input[type=checkbox].switcher-checkbox {
  display: none;

  &:checked {

    + .switcher-label {
      background: #0077f5;

      &:before {
        color: #0077f5;
      }

      &:after {
        color: #fff;
      }

      .switcher-slide {
        background: #fff;
        left: 2px;
      }
    }
  }
}

input[type=checkbox] + .switcher-label {
  background: #98a3af;
  border-radius: 15px;
  cursor: pointer;
  display: inline-block;
  font-family: $font-stack-base;
  font-size: 14px;
  font-weight: $font--bold;
  height: 30px;
  overflow: hidden;
  position: relative;
  width: 73px;

  &:before {
    color: #fff;
    content: 'On';
    display: block;
    left: 9px;
    line-height: 30px;
    position: absolute;
    transition: color 0.4s ease;
    z-index: 1;
  }

  &:after {
    color: #98a3af;
    content: 'Off';
    display: block;
    line-height: 30px;
    position: absolute;
    right: 10px;
    transition: color 0.4s ease;
    z-index: 1;
  }
}
