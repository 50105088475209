@import 'qtip2/dist/jquery.qtip.min.css';

.qtip-bcs {
  background-color: #fff;
  border-color: transparent;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(4,25,55, 0.2);
  color: $color-text--base;
  font-size: $font--sm;
  margin-left: 5px;

  &.has-error {
    color: $color-status--error;
  }
}


.qtip-bcs .qtip-titlebar {
  background-color: #f00;
  border-radius: 4px 4px 0 0;
}
