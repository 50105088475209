.log-out {
  display: inline-block;
  height: 100%;
  fill: $color-icon--base;
  position: relative;
  margin-left: 15px;
  vertical-align: middle;
  width: 30px;

  & > .svg-icon {
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%)
  }

  &:hover > .svg-icon,
  &:focus > .svg-icon {
    fill: #212b44;
  }
}
