.profile-info {
  border-radius: 6px;
  background-color: #eef2f6;
  padding: 20px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  & > h2 {
    margin: 0 0 10px;

    & > .btn.btn--edit {
      margin-left: 15px;
    }
  }

  & h3 {
    margin: 0 0 15px;
  }

  & h4 {
    margin: 0;
  }

  & label {
    color: #98a3af;
    display: block;
    font-size: $font--sm;
    font-weight: $font--bold;
  }

  & input {
    font-size: $font--xm;
    background-color: transparent;
    border: none;
    border-radius: 0;
    height: auto;
    padding: 0;
    text-align: left;
    width: 100%;
  }

  & .btn.btn--ghost {
    padding-left: 13px;
    padding-right: 13px;
  }

  &.profile-access-data input {
    text-align: center;
  }
}

.profile-info__group {
  &:not(:last-child) {
    border-bottom: 1px solid #d8dfe6;
    margin-bottom: 10px;
  }
}

.profile-info__row {

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:after {
    clear: both;
    content: "";
    display: table;
  }
}

.profile-info__cell {
  float: left;

  &.full-width {
    width: calc(100% - 185px);
  }

  &.third-width {
    width: 21.5%;
  }
}

.profile-info__aside-cell {
  float: right;
  width: 185px;

  & > .profile-info__status {
    margin-left: 50px;
  }
}

.profile-info__status {
  background-position: 0 50%;
  background-size: 17px 17px;
  background-repeat: no-repeat;
  display: inline-block;
  font-size: $font--xm;
  font-weight: $font--normal;
  padding-left: 25px;
  vertical-align: middle;

  &.is-approved {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%3Cg%20fill%3D%22%2376b345%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M10%200C4.486%200%200%204.486%200%2010s4.486%2010%2010%2010%2010-4.486%2010-10S15.514%200%2010%200zm0%2019.23C4.91%2019.23.77%2015.09.77%2010%20.77%204.91%204.91.77%2010%20.77c5.09%200%209.23%204.14%209.23%209.23%200%205.09-4.14%209.23-9.23%209.23z%22/%3E%3Cpath%20d%3D%22M14.712%205.898l-5.91%206.65L5.24%209.7c-.165-.133-.408-.106-.54.06-.133.165-.107.407.06.54l3.845%203.077c.07.057.156.085.24.085.107%200%20.212-.044.288-.13l6.154-6.923c.14-.16.127-.403-.032-.543-.16-.142-.402-.128-.543.03z%22/%3E%3C/g%3E%3C/svg%3E');
  }

  &.is-declined {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%3Cg%20fill%3D%22%23e94f4f%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M13.857%2014.5l.643-.643L10.643%2010%2014.5%206.143l-.643-.643L10%209.357%206.143%205.5l-.643.643L9.357%2010%205.5%2013.857l.643.643L10%2010.643%22/%3E%3Cpath%20d%3D%22M20%2010c0-5.523-4.477-10-10-10S0%204.477%200%2010s4.477%2010%2010%2010%2010-4.477%2010-10zM.91%2010C.91%204.98%204.98.91%2010%20.91s9.09%204.07%209.09%209.09-4.07%209.09-9.09%209.09S.91%2015.02.91%2010z%22/%3E%3C/g%3E%3C/svg%3E');
  }

  &.is-pending {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2221%22%20height%3D%2219%22%20fill-rule%3D%22evennodd%22%20fill%3D%22%2398a3af%22%3E%3Cpath%20d%3D%22M15.544%2010.422C15.824%209.665%2016%208.855%2016%208c0-3.866-3.134-7-7-7-.855%200-1.665.175-2.422.456L5.794.673C6.776.243%207.86%200%209%200c4.418%200%208%203.582%208%208%200%201.14-.243%202.224-.673%203.206l-.783-.784zm-4.122%204.122l.784.783C11.224%2015.757%2010.14%2016%209%2016c-4.418%200-8-3.582-8-8%200-1.14.243-2.224.673-3.206l.783.784C2.176%206.335%202%207.145%202%208c0%203.866%203.134%207%207%207%20.855%200%201.665-.175%202.422-.456zM4%203v4L0%203h4zm10%2010V9l4%204h-4z%22/%3E%3C/svg%3E');
  }
}

.profile-info__docs-list {
  margin-bottom: 20px;

  & .profile-info__group {
    border-bottom: 1px solid #d8dfe6;
    padding-bottom: 10px;
  }

  & .profile-info__cell {
    width: 24.5%;

    & > span {
      font-size: $font--xm;
    }
  }

  & .profile-info__aside-cell {
    width: 120px;

    & .profile-info__status {
      margin-left: 0;
    }
  }
}

.profile-info__docs-upload {
  & form {
    margin-bottom: 20px;
  }

  & select {
    text-align: left;
    width: 185px;
  }

  .select2-container--bcs .select2-selection--single .select2-selection__rendered {
    text-align: left;
  }

  & .file-upload {
    display: inline-block;
    margin-left: 10px;
    max-width: 260px;
    vertical-align: middle;

    &.is-focused {
      & .file-upload__caption {
        //width: 170px;
      }
    }

    &.is-file-chosen {
      & .btn.btn--round {
        display: block;
      }
    }
  }

  & .file-upload__caption {
    transition: width 0.2s;
    width: 140px;
  }

  & .file-upload__btn-group {
    & .btn.btn--ghost {
      padding-left: 18px;
      padding-right: 18px;
    }

    & .btn.btn--round {
      display: none;
      position: absolute;
      top: 50%;
      margin-top: -13px;
      left: -36px;
      color: $color-text--base;
      fill: $color-text--base;
      border-color: #e2e8ed;
      width: 26px;
      height: 26px;
      background-color: #e2e8ed;

      &:hover {
        background-color: $color-yellow--base;
      }

      & .svg-icon {
        width: 10px;
        height: 10px;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }

  & .note {
    background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%3Cpath%20fill%3D%22%23aaafb4%22%20fill-rule%3D%22evenodd%22%20d%3D%22M9.997%200C4.482%200%20.012%204.47.012%209.985s4.47%2010.01%209.985%2010.01%209.985-4.497%209.985-9.986C19.982%204.493%2015.512%200%209.997%200zm0%2019.026C5.03%2019.026.98%2014.976.98%2010.01S5.03.965%209.997.965c4.966%200%209.017%204.05%209.017%209.043%200%204.992-4.05%209.016-9.017%209.016zm.496-4.94c0%20.29-.235.523-.523.523-.288%200-.522-.235-.522-.523%200-.288.234-.523.522-.523.288%200%20.523.235.523.523zm-1.02-2.562v-5.88c0-.262.21-.47.47-.47.262%200%20.47.208.47.47v5.88c0%20.26-.208.47-.47.47-.26.03-.47-.21-.47-.47z%22/%3E%3C/svg%3E') 0 0 no-repeat;
    background-size: 17px 17px;
    color: #aaafb4;
    padding-left: 25px;

    & > p {
      margin: 0;
    }
  }
}

