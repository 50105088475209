.lang-switcher {
  display: inline-block;
  height: 80px;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  vertical-align: middle;
  width: 70px;

  .p-header--no-login & {
    background-color: transparent;
    float: right;
    margin-left: 40px;
    transition: $transition--default;

    &:hover {
      background-color: #e2eaf0;
    }
  }

  .p-header--registration & {
    background-color: transparent;
    color: #fff;
    float: right;
    margin-right: 40px;
  }

  &__current {
    height: 100%;
    width: 100%;

    & > span {
      background: linear-gradient(to right, $color-link--base 33%, rgba(255,255,255,0) 0%) 0 100% repeat-x;
      background-size: 03px 1px;
      color: $color-link--base;
      display: block;
      font-size: $font--md;
      /* 4px = arrow width */
      left: calc(50% - 4px);
      position: absolute;
      top: 50%;
      transition: $transition--default;
      transform: translate(-50%, -50%);

      &:after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid $color-link--base;
        content: "";
        display: block;
        height: 0;
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
        transition: $transition--default;
        width: 0;
      }

      .p-header--registration & {
        background: linear-gradient(to right, transparent 33%, rgba(255,255,255,0) 0%) 0 100% repeat-x;
        background-size: 3px 1px;
        color: #fff;

        &:after {
          border-top-color: rgba(255, 255, 255, 0.6);
        }
      }
    }



    &:hover {
      cursor: pointer;

      & > span {
        background-image: linear-gradient(to right, $color-link--accent 33%, rgba(255,255,255,0) 0%);
        color: $color-link--accent;

        &:after {
          border-top: 0;
          border-bottom: 4px solid $color-link--accent;
        }

        .p-header--registration & {
          background-image: linear-gradient(to right, #fff 33%, rgba(255, 255, 255, 0) 0%);
          color: #fff;

          &:after {
            border-bottom-color: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }
  }

  &__dropdown {
    background-color: #e2eaf0;
    bottom: -100%;
    left: 0;
    list-style: none;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    transition: $transition--default;
    visibility: hidden;
    width: 100%;

    .p-header--registration & {
      background-color: #122f5b;
    }

    & > li {
      height: 40px;
      line-height: 40px;
      text-align: center;
      transition: $transition--default;

      &:hover {
        background-color: #ebf1f6;

        .p-header--registration & {
          background-color: #2E629D;
        }
      }
    }

    & > li > span {
      background: linear-gradient(to right, $color-link--base 33%, rgba(255,255,255,0) 0%) 0 100% repeat-x;
      background-size: 3px 1px;
      color: $color-link--base;
      transition: $transition--default;

      .p-header--registration & {
        background: linear-gradient(to right, transparent 33%, rgba(255,255,255,0) 0%) 0 100% repeat-x;
        background-size: 3px 1px;
        color: #fff;
      }


      &:hover {
        background: linear-gradient(to right, $color-link--accent 33%, rgba(255,255,255,0) 0%) 0 100% repeat-x;
        background-size: 3px 1px;
        color: $color-link--accent;

        .p-header--registration & {
          background: linear-gradient(to right, #fff 33%, rgba(255,255,255,0) 0%) 0 100% repeat-x;
          background-size: 3px 1px;
          color: #fff;
          transition: $transition--default;
        }
      }
    }

    .lang-switcher__current:hover & {
      opacity: 1;
      visibility: visible;
    }
  }
}
