.p-main {
  background-color: #fff;
  display: flex;
  position: relative;
  margin-bottom: auto;
  top: 0;
  width: 100%;
  z-index: 1;

  .p-header--registration ~& {
    overflow: unset;
    position: relative;
    padding-bottom: 50px;
    top: 0;
  }

  .page.sidebar-is-visible & {
    filter: blur(5px);
    overflow: hidden;
  }
}
