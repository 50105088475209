.hint-block {
  background-color: #d8dfe6;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 14px;
  line-height: 14px;
  position: relative;
  text-align: center;
  transition: $transition--default;
  vertical-align: top;
  width: 14px;

  &::before {
    color: #212325;
    content: "?";
    display: inline;
    font-size: $font--sm;
    font-weight: $font--bold;
  }

  &:hover {
    background-color: $color-yellow--base;
  }

  .has-error + & {
    background-color: $color-status--error;
  }
}
