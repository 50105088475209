.p-content {
  padding: 20px 40px 20px 20px;
}

.content-inner {
  flex: 1 0 auto;
  max-width: 1440px;
  width: calc(100% - 260px);
  min-width: 920px;
  margin: 0 auto;
}
