.strategy-selector {
  border: 1px solid #d8dfe6;
  border-radius: 6px;
  padding: 0;

  .strategy-types {
    & > .btn {
      border: 1px solid transparent;
      border-radius: 17px;
      color: $color-link--base;
      cursor: pointer;
      display: inline-block;
      font-size: $font--md;
      font-weight: $font--bold;
      vertical-align: top;
      margin: 0;
      padding: 8px 10px 7px;
      position: relative;
      text-align: center;
      transition: $transition--default;

      & > span {
        border-bottom: 1px dotted $color-link--base;
        display: inline-block;
        vertical-align: middle;
      }

      &:hover {
        color: $color-link--accent;

        & > span {
          border-bottom-color: $color-link--accent;
        }
      }

      &.is-active {
        color: #fff;
        background-color: $color-link--base;

        & > span {
          border-bottom-color: transparent;
        }
      }
    }
  }

  .strategy-invest-sum {
    &:after {
      clear: both;
      content: "";
      display: table;
    }

    & > input {
      height: 30px;
      float: left;
      margin-left: 25px;
      margin-right: 15px;
      text-align: left;
      width: 245px;
    }

    & > .strategy-invest-sum__switcher {
      background-color: $color-link--base;
      border-radius: 15px;
      height: 30px;
      float: left;
      font-size: 0;
      padding: 2px 1px;
      width: 88px;

      & > .btn {
        background-color: transparent;
        border-radius: 50%;
        color: #fff;
        font-size: $font--sm;
        height: 26px;
        text-align: center;
        width: 26px;

        &:not(:last-child) {
          margin-right: 4px;
        }

        & > i {
          display: inline-block;
          font-size: $font--sm;
          height: 100%;
          width: 100%;

          &::before {
            line-height: 26px;
          }
        }

        & > span {
          font-size: 0;
        }
      }

      & > .btn.is-active {
        background-color: #fff;
        color: $color-link--base;
      }
    }
  }
}

.strategy-selector__body {
  background-color: #f0f4f7;
  padding: 10px 20px 0;
}

.strategy-selector__footer {
  background-color: #fff;
  border-top: 1px solid #d8dfe6;
  border-radius: 0 0 6px 6px;
  padding: 24px 0 25px 20px;

  & > .search-results {
    display: inline-block;
    margin-left: 40px;
    vertical-align: middle;
  }
}

.strategy-selector__row {
  padding: 20px 0;

  &:first-child {
    padding: 0 0 15px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #d8dfe6;
  }
}

.strategy-selector__row--type {

  & .strategy-selector__label {
    margin-right: 8px;
  }
}

.strategy-selector__row--range {
  font-size: 0;

  & .strategy-selector__label {
    margin: 0;
    width: 160px;
  }
}

.strategy-selector__label {
  color: #a6acb0;
  display: inline-block;
  font-size: $font--md;
  margin-right: 20px;
  vertical-align: middle;
}

.strategy-selector__cell {
  display: inline-block;
  vertical-align: middle;
}

.strategy-selector__range {
  width: 360px;

  .irs-with-grid {
    height: 40px;
  }

  .irs-line {
    background-color: #c8d1da;
    height: 2px;
    top: 25px;
  }
  .irs-line-left {
    height: 2px;
  }

  .irs-line-mid {
    height: 2px;
  }

  .irs-line-right {
    height: 2px;
  }

  .irs-bar {
    display: none;
  }

  .irs-bar-edge {
    display: none;
  }

  .irs-shadow {
    display: none;
  }

  .irs-slider {
    width: 16px;
    height: 18px;
    top: 22px;
  }

  .irs-min,
  .irs-max {
    color: $color-text--base;
    font-size: $font--md;
    font-weight: $font--bold;
    line-height: $lh--base;
    text-shadow: none;
    top: 0;
    padding: 1px 3px;
    background: transparent;
    border-radius: 0;
  }

  .irs-single {
    color: #fff;
    font-size: $font--md;
    font-weight: $font--bold;
    line-height: $lh--base;
    text-shadow: none;
    padding: 2px 7px;
    background: $color-link--base;
    border-radius: 9px;
    transition: transform 0.3s ease-out;
    top: -10px;
  }

  .irs-single:active {
    transform: scale(1.1);
    top: -12px;
  }

  .irs-single:after {
    position: absolute;
    display: block;
    content: "";
    bottom: -8px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -6px;
    overflow: hidden;
    border: 4px solid transparent;
    border-left-width: 6px;
    border-right-width: 6px;
    border-top-color: $color-link--base;
    transition: transform 0.1s ease-out;
  }

  .irs-single:active:after {
    transform: scale(1.1);
  }

  .irs-grid-pol {
    width: 2px;
    height: 7px;
    background-color: #c8d1da;
  }

  .irs-grid-text {
    display: none;
  }
}
