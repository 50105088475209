.user-account-action {
  border-radius: 8px;
  color: $color-icon--accent;
  font-family: $font-sans-caption;
  font-size: $font--md;
  font-weight: $font--bold;
  margin: 0 5px;
  padding: 10px 22px 9px;

  &:hover {
    box-shadow: 0 3px 5px 0 rgba(253, 214, 85, 0.5);
  }

  &:active {
    box-shadow: none;
  }

  .p-header__user-info > & {
    color: $color-icon--accent;
    float: right;
    position: relative;
    min-width: 150px;
    top: 50%;
    transform: translateY(-50%);
  }
}
