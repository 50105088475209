.p-header {
  background-color: #ebf1f6;
  height: 80px;
  min-width: $width--min;
  position: relative;
  overflow: visible;
  width: 100%;
  z-index: 10;
  display: flex;

  &--no-login {
    background-color: #ebf1f6;

    .p-header__user-info .btn {
      border-radius: 8px;
      color: #212b44;
      font-weight: $font--bold;
      float: right;
      height: 40px;
      padding: 11px 25px 10px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__nav-toogle {
    background-color: #11233f;
    float: left;
    height: 100%;
    padding: 28px 30px 28px 40px;
    width: 100px;
  }

  &__main-logo {
    background-color: $color-dark-blue;
    float: left;
    height: 100%;
    padding: 18px 40px 20px;
    width: 260px;
    z-index: 1;

    & .main-logo {
      margin: 0 auto;
    }

    .p-header--registration & {
      padding: 18px 40px 20px;
      width: 200px;
    }
  }

  &__user-info {
    background-color: #ebf1f6;
    height: 100%;
    padding: 0 20px;
    position: relative;
    min-width: 920px;
    max-width: 1440px;
    width: calc(100% - 260px);
    margin: 0 auto;
  }

  &__extra-opts {
    background-color: #ebf1f6;
    float: right;
    height: 100%;
    width: 140px;
  }

  &::before,
  &::after {
    content: "";
    display: table;
  }

  &::after {
    clear: both;
  }

  &--registration {
    background-color: #122f5b;
    position: sticky;

    &::before{
      border-left: 9999px solid #122f5b;
      box-shadow: 9999px 0 0 #122f5b;
      bottom: 0;
      content: " ";
      left: -9999px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
      display: block;
    }

    &__main-logo {
      margin-left: 120px;
    }
  }

  &__epigraph {
    color: #fff;
    float:  left;
    font-size: 16px;
    font-weight: 700;
    padding: 18px 0 20px;
    width: 300px;

    & p {
      margin: 0;
    }
  }

  &__phone {
    color: #fff;
    float: right;
    padding: 10px 0 20px;
    position: relative;
    margin-right: 10px;
    margin-left: auto;

    & a {
      color: #fff;
      display: block;
      text-decoration:  none;
      margin: 0;
      text-align: right;

      &:hover {
        color: #5aa5f3;
      }
    }

    & .phone-number {
      color: #fff;
      display:  block;
      font-size: 24px;
      font-weight:  700;
      text-align:  right;
      margin: 0;
    }

    & .svg-icon {
      fill: #fff;
      vertical-align: baseline;
    }
  }
}
