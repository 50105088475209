.svg-icon {
  display: inline-block;
  fill: inherit;
  transition: $transition--default;
  vertical-align: middle;

  &--payment-method {
    fill: $color-icon--base;

    .data-table tr:hover & {
      fill: $color-dark-blue;
    }
  }
}
