.registration-info {
  & h2 {
    color: $color-icon--accent;
    font-size: 16px;
    font-weight: $font--bold;
    line-height: 1.5;
    margin: 0 0 15px;
  }

  & p {
    color: $color-icon--accent;
    line-height: 1.5;
    margin: 0 0 10px;
  }

  &__advantages {
    margin-bottom: 20px;

    ul {
      margin: 0;
      padding-left: 16px;
    }
  }

  &__contacts {
    border: 1px solid #d8dfe6;
    border-radius: 6px;
    padding: 20px 15px 30px 16px;

    & .link--phone {
      color: $color-icon--accent;
      display: block;
      font-size: 30px;
      padding-left: 35px;
      position: relative;
    }

    .link--phone .svg-icon {
      position: absolute;
      left: 0;
      transform: translateY(-50%);
      top: 50%;
      fill: #ffc400;
    }
  }

  &__note {
    color: rgba(33, 43, 68, 0.6);
    display: block;
    font-size: 12px;
    line-height: 1.667;
    padding-left: 35px;
  }
}