.data-table-wrapper {
  border: 1px solid #d8dfe6;
  border-radius: 6px;
  padding: 10px;

  & > h2 {
    font-size: $font--xm;
    margin: 0 0 -10px; // to remove the space left by border-spacing
    padding-left: 10px;
  }

  & + div {
    margin-top: 20px;
  }

  & > header {
    background-color: #f0f4f7;
    margin: -10px -10px 15px;
    padding: 10px 0;
    text-align: center;

    & > span {
      display: inline-block;
      font-size: $font--xm;
      font-weight: $font--bold;
      margin-right: 12px;
      vertical-align: middle;
    }
  }
}
